import React from 'react';

const PenSectionIcon = ({ width = 28, height = 28 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.7504 25.0623L12.5708 18.242C13.3493 18.4499 14.2142 18.2484 14.8249 17.6377C15.7362 16.7265 15.7362 15.2491 14.8249 14.3379C13.9137 13.4267 12.4364 13.4267 11.5251 14.3379C10.9144 14.9486 10.713 15.8135 10.9209 16.5921L4.10047 23.4124L2.86304 22.175C6.16287 18.3251 7.40031 14.063 9.05022 6.91328L16.4749 6.08832L23.0746 12.688L22.2495 20.1126C15.1 21.7625 10.8376 23 6.98783 26.2998L5.7504 25.0623ZM19.3622 2.376L26.7665 9.78038C26.9944 10.0082 26.9944 10.3775 26.7665 10.6053C26.6791 10.6927 26.5663 10.7501 26.4442 10.7692L24.7244 11.0381L18.1248 4.4384L18.3722 2.70598C18.4179 2.38706 18.7133 2.16546 19.0322 2.21101C19.1572 2.22886 19.2729 2.28675 19.3622 2.376Z"
        fill="white"
      />
    </svg>
  );
};

export default PenSectionIcon;

import { Button } from '@material-tailwind/react';
import { Formik } from 'formik';
import { t } from 'i18next';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ConfirmationModal from '../../../../../components/confirm';
import ContentLayout from '../../../../../components/content-layout';
import FormInput from '../../../../../components/input/FormInput';
import Loader from '../../../../../components/loader';
import { useGetAllQuery } from '../../../../../hooks/query';
import { setHall } from '../../../../../store/slices/settingsSlice';
import ElementsList from './ElementsList';
import HallGrid from './HallGrid';
import './screen.css';

const HallWrapper = ({ activeContent, setActiveContent }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const hall = useSelector((state) => state.settings.hall);
  const [coordinates, setCoordinates] = useState({
    x: parseInt(searchParams.get('xCoordinate'), 10) || get(hall,"hall.width",32),
    y: parseInt(searchParams.get('yCoordinate'), 10) || get(hall,"hall.width",20)
  });

  console.log(hall);
  
  const [selectedElements, setSelectedElements] = useState([]);

  const [activeElement, setActiveElement] = useState();
  const [targetItems, setTargetItems] = useState(() => {
    return Array.from({ length: coordinates.y }, () =>
      Array.from({ length: coordinates.x }, () => null)
    );
  });
  const [currentBox, setCurrentBox] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [pendingCoordinates, setPendingCoordinates] = useState(coordinates);

  const { data: elements, isLoading } = useGetAllQuery({
    key: 'hall-elements',
    url: 'admin/halls/elements'
  });

  const handleConfirm = () => {
    setShowConfirm(false);
    setTargetItems((prevItems) => {
      const newRows =
        pendingCoordinates.y < prevItems.length
          ? prevItems.slice(0, pendingCoordinates.y)
          : prevItems.concat(
              Array.from({ length: pendingCoordinates.y - prevItems.length }, () =>
                Array.from({ length: pendingCoordinates.x }, () => null)
              )
            );
      const newItems = newRows.map((row) =>
        pendingCoordinates.x < row.length
          ? row.slice(0, pendingCoordinates.x)
          : row.concat(Array.from({ length: pendingCoordinates.x - row.length }, () => null))
      );
      return newItems;
    });
    setCoordinates(pendingCoordinates);
  };

  const updateSectionElementInfo = (hall, sectionId, newElementInfo) => {
    return {
      ...hall,
      hall: {
        ...get(hall, 'hall.sections', []),
        sections: get(hall, 'hall.sections', []).map((section) =>
          section.id == sectionId
            ? {
                ...section,
                elementInfo: {
                  x: coordinates.x,
                  y: coordinates.y,
                  hallElementDocuments: newElementInfo
                }
              }
            : section
        )
      }
    };
  };
  const handleConfirmSave = () => {
    setShowConfirm(false);

    const updatedHall = updateSectionElementInfo(
      hall,
      searchParams.get('section'),
      selectedElements
    );
    dispatch(setHall(updatedHall));
    setSearchParams({ tab: 2 });
    setActiveContent(2);
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };
  const handleCancelSave = () => {
    setShowConfirmSave(false);
  };

  useEffect(() => {
    if (pendingCoordinates.x < targetItems[0].length || pendingCoordinates.y < targetItems.length) {
      setShowConfirm(true);
    } else {
      setTargetItems((prevItems) =>
        prevItems
          .map((row) =>
            row.length < pendingCoordinates.x
              ? [...row, ...Array.from({ length: pendingCoordinates.x - row.length }, () => null)]
              : row
          )
          .concat(
            Array.from({ length: pendingCoordinates.y - prevItems.length }, () =>
              Array.from({ length: pendingCoordinates.x }, () => null)
            )
          )
      );
      setCoordinates(pendingCoordinates);
    }
  }, [pendingCoordinates]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ContentLayout>
      <div className="flex gap-6">
        <ElementsList
          setSelectedElements={setSelectedElements}
          selectedElements={selectedElements}
          items={elements.data}
          setCurrentBox={setCurrentBox}
          currentBox={currentBox}
          activeElement={activeElement}
          setActiveElement={setActiveElement}
        />
        <div>
          <Formik
            initialValues={{
              xCordinate: coordinates.x,
              yCordinate: coordinates.y
            }}
            onSubmit={() => {}}>
            {({ setFieldValue }) => (
              <div className="flex gap-6 mt-6">
                <FormInput
                  onBlur={(val) => {
                    const newX = +val.target.value;
                    setFieldValue('xCordinate', newX);
                    setPendingCoordinates((prev) => ({
                      ...prev,
                      x: newX
                    }));
                  }}
                  name="xCordinate"
                  label={t('x_coordinate')}
                />
                <FormInput
                  onBlur={(val) => {
                    const newY = +val.target.value;
                    setFieldValue('yCordinate', newY);
                    setPendingCoordinates((prev) => ({
                      ...prev,
                      y: newY
                    }));
                  }}
                  name="yCordinate"
                  label={t('y_coordinate')}
                />
              </div>
            )}
          </Formik>

          <HallGrid
            setSelectedElements={setSelectedElements}
            coordinates={coordinates}
            activeElement={activeElement}
            items={targetItems}
            setItems={setTargetItems}
            setCurrentBox={setCurrentBox}
            currentBox={currentBox}
            elements={elements.data}
          />

          <div className="flex items-center gap-6 mt-10 fixed bottom-0">
            <Button className="bg-main-black">{t('cancel')}</Button>
            <Button
              onClick={() => setShowConfirmSave(true)}
              type="submit"
              className="bg-main-green">
              {t('save')}
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationModal show={showConfirm} onConfirm={handleConfirm} onCancel={handleCancel} />
      <ConfirmationModal
        title="Do you wanna create hall"
        show={showConfirmSave}
        onConfirm={handleConfirmSave}
        onCancel={handleCancelSave}
      />
    </ContentLayout>
  );
};

export default HallWrapper;

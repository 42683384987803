import { get } from 'lodash';
import { useEffect, useState } from 'react';
import ToastError from '../../components/notification/ErrorNotification';
import { httpClient } from '../../services';

const useGetImages = ({ data,url="figure" }) => {
  const [base64Images, setBase64Images] = useState({});

  useEffect(() => {
    const imagesToFetch = data.filter(
      (item) =>
        get(item, url, '').includes('api.dev.biletick.uz') &&
        !base64Images[get(item, url, '')]
    );

    imagesToFetch.forEach((image) => {
      fetchImage(get(image, url, ''));
    });
  }, [data, base64Images]);

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const fetchImage = async (url) => {
    try {
      const response = await httpClient.get(url, { responseType: 'arraybuffer' });
      const base64 = arrayBufferToBase64(response.data);
      const dataUrl = `data:image/png;base64,${base64}`;
      setBase64Images((prev) => ({ ...prev, [url]: dataUrl }));
    } catch (error) {
      ToastError(error);
    }
  };

  return {
    base64Images
  };
};

export default useGetImages;

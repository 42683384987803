import { Button as SButton, Flex, Menu, Text } from '@mantine/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Button from '../../components/button';
import ContentLayout from '../../components/content-layout';

import FormInput from '../../components/input/FormInput';
import SelectInput from '../../components/select/select-input';
import { Routes } from '../../constants/routes';
import {  usePostQuery } from '../../hooks/query';


 const STATUS_OPTION = (t) => {
  return [
    { value: true, label: <Text c="#088157">{t('Active')}</Text> },
    { value: false, label: <Text c="#C61F1F">{t('Deactive')}</Text> }
  ];
};

function CreateSeances() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { mutate, isLoading } = usePostQuery({
    listKeyId: 'template-sms'
  });

  const addSms = (data) => {
    const submittingData = {
      template: {
        uz: data.templateUz,
        en: data.templateEn,
        ru: data.templateRu
      },
      name: {
        uz: data.uz,
        en: data.en,
        ru: data.ru
      },
      enabled: data.status,
      creator: data.creator,
      placeholderNames: data.placeholderNames
    };

    mutate(
      {
        url: '/template/sms/',
        attributes: submittingData
      },
      {
        onSuccess: ({ data }) => {
          navigate(Routes.SMS_TEMPLATE);
        },
        onError: () => {}
      }
    );
  };

  return (
    <ContentLayout breadcrumb={true}>
      <Formik initialValues={{ figure: true, elementSize: true, type: '' }} onSubmit={addSms}>
        {({ setFieldValue, values, errors, setFieldTouched }) => (
          <Form>
            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Название шаблона')}</p>
            <div className="flex items-center gap-6 my-6">
              <FormInput type="text" name="uz" label={t('Узбекский')} />
              <FormInput type="text" name="ru" label={t('Русский')} />
              <FormInput type="text" name="en" label={t('Английский')} />
            </div>

            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Содержания шаблона')}</p>

            <FormInput wFull type="text" name="templateUz" label={t('Узбекский')} />
            <FormInput wFull type="text" name="templateRu" label={t('Русский')} />
            <FormInput wFull type="text" name="templateEn" label={t('Английский')} />

            <p className="text-[20px] font-semibold my-6 text-gray-3">{t('Информация шаблона')}</p>

            <div className="flex items-center gap-6 my-6">
              <FormInput type="text" name="creator" label={t('creator')} />
              <SelectInput
                label={t('status')}
                onChange={(ev) => setFieldValue('status', ev?.value)}
                options={STATUS_OPTION(t)}
              />
            </div>

            <div className="flex items-center gap-6 mt-10">
              <Button className="bg-main-black">Отменить</Button>
              <Button type="submit" className="bg-accent-green">
                {t('Добавить')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ContentLayout>
  );
}

export default CreateSeances;

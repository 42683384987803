import React from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

function PrivateRouter({ children }) {
  const [cookies] = useCookies(['accessToken']);
  // test
  return cookies?.accessToken  ? children : <Navigate to="/login" />;
}

export default PrivateRouter;

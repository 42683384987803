import { Flex } from '@mantine/core';
import { Button } from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import { get } from 'lodash';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ConfirmationModal from '../../../../../components/confirm';
import ContentLayout from '../../../../../components/content-layout';
import { setHall } from '../../../../../store/slices/settingsSlice';
import ElementsList from './ElementsList';
import { default as DrawingCanvas, default as HallGrid } from './HallGrid';
import './screen.css';

const validationSchema = Yup.object().shape({
  uz: Yup.string().required(),
  ru: Yup.string().required(),
  en: Yup.string().required(),
  rotate: Yup.string()
    .required('Rotate is required')
    .min(-180, 'Must be between -180 and 180')
    .max(180, 'Must be between -180 and 180')
});
const HallSectionWrapper = ({ setActiveContent, setSearchParams }) => {
  const hall = useSelector((state) => state.settings.hall);
  const [image, setImage] = useState(null);
  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0
  });
  const [showModal, setShowModal] = useState(false);
  const [newCanvasSize, setNewCanvasSize] = useState({
    width: canvasSize.width,
    height: canvasSize.height
  });
  const [url, setUrl] = useState(get(hall, 'hall.backgroundUrl', ''));
  const dispatch = useDispatch();
  const [currentDrawing, setCurrentDrawing] = useState([]);
  const canvasRef = useRef(null);
  const [drawings, setDrawings] = useState(get(hall, 'hall.sections', []));
  const [isClose, setIsClose] = useState(false);
  const [isDrawingEnabled, setIsDrawingEnabled] = useState(false); // New state for drawing mode
  const [element, setElement] = useState({
    isActive: false
  });

  const handleSaveDrawing = (values, errors, handleReset) => {
    
    if (currentDrawing.length > 0) {
      setDrawings([
        ...drawings,
        {
          name: {
            uz: element.uz,
            ru: element.ru,
            en: element.en
          },
          color: element.color ? element.color : '#C61F1F',
          isActive: element.isActive,
          polygon: currentDrawing,

          textX: '1',
          textY: '1',
          textRotateAngle: element.rotate,
          showMap: true,
          elementInfo: {},
          id: drawings.length > 0 ? drawings[drawings.length - 1].id + 1 : 0
        }
      ]);
      setCurrentDrawing([]);
      setIsClose(true);
      setElement({});
      const updatedHall = {
        ...hall,
        hall: {
          width: canvasSize.width,
          height: canvasSize.height,
          backgroundUrl: url,
          sections: drawings
        }
      };
      dispatch(setHall(updatedHall));
      setElement({
        isActive: false
      });
      setIsDrawingEnabled(false);
    }
  };

  const handleReset = () => {
    setDrawings([]);
    setCurrentDrawing([]);
  };

  const handleRemoveLastDrawing = () => {
    setDrawings(drawings.slice(0, -1));
  };

  const handleChangeCanvasSize = () => {
    if (drawings.length > 0 || currentDrawing.length > 0) {
      setShowModal(true);
    } else {
      setCanvasSize({
        width: newCanvasSize.width,
        height: newCanvasSize.height
      });
    }
  };
  const drawLines = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw image if it exists and has loaded
    if (image) {
      const img = new Image();
      img.onload = () => {
        canvas.width = canvasSize.width;
        canvas.height = canvasSize.height;
        ctx.drawImage(img, 0, 0, canvasSize.width, canvasSize.height);

        // ctx.strokeStyle = element.color ? element.color : 'red';
        ctx.lineWidth = 4;
        // [[{x,y}], []] => [{ polygon: []}]
        // Draw existing drawings
        drawings.forEach((drawing) => {
          ctx.strokeStyle = drawing.color ? drawing.color : 'red'; // Set the stroke color for each drawing
          ctx.lineWidth = 4;
          if (drawing.polygon.length > 1) {
            ctx.beginPath();
            ctx.moveTo(drawing.polygon[0].x, drawing.polygon[0].y);
            for (let i = 1; i < drawing.polygon.length; i++) {
              ctx.lineTo(drawing.polygon[i].x, drawing.polygon[i].y);
            }
            ctx.stroke();
          }
        });

        // Draw current drawing
        if (currentDrawing.length > 1) {
          ctx.beginPath();
          ctx.moveTo(currentDrawing[0].x, currentDrawing[0].y);
          for (let i = 1; i < currentDrawing.length; i++) {
            ctx.lineTo(currentDrawing[i].x, currentDrawing[i].y);
          }
          ctx.stroke();
        }
      };
      img.src = image;
    }
  };

  const handleConfirmChangeSize = () => {
    const oldDrawings = drawings.map((drawing) =>
      drawing.polygon.map((point) => ({
        x: (point.x / canvasSize.width) * newCanvasSize.width,
        y: (point.y / canvasSize.height) * newCanvasSize.height
      }))
    );

    setDrawings([]);
    setCurrentDrawing([]);

    setCanvasSize({
      width: newCanvasSize.width,
      height: newCanvasSize.height
    });
    setShowModal(false);

    setTimeout(() => {
      setDrawings(oldDrawings);
    }, 100);
  };

  const handleCancelChangeSize = () => {
    setShowModal(false);
  };

  return (
    <ContentLayout>
      <Formik
        initialValues={{
          figure: true,
          elementSize: true,
          type: '',
          uz: '',
          ru: '',
          en: ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleSaveDrawing}>
        {({ setFieldValue, values, handleSubmit, errors, touched, handleReset }) => (
          <Form className="flex gap-6">
            <ElementsList
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              values={values}
              url={url}
              setUrl={setUrl}
              element={element}
              setElement={setElement}
              isDrawingEnabled={isDrawingEnabled}
              setIsDrawingEnabled={setIsDrawingEnabled}
              setImage={setImage}
              setCanvasSize={setCanvasSize}
              drawings={drawings}
              setActiveContent={setActiveContent}
              setSearchParams={setSearchParams}
            />
            <div>
              <div className="flex gap-6 mt-6 ">
                <div className="flex space-x-2 mt-4">
                  <input
                    type="number"
                    placeholder="Width"
                    value={newCanvasSize.width}
                    onChange={(e) =>
                      setNewCanvasSize({ ...newCanvasSize, width: parseInt(e.target.value) })
                    }
                    className=" p-5 outline-none rounded-[12px]"
                  />
                  <input
                    type="number"
                    placeholder="Height"
                    value={newCanvasSize.height}
                    onChange={(e) =>
                      setNewCanvasSize({ ...newCanvasSize, height: parseInt(e.target.value) })
                    }
                    className="p-5 outline-none rounded-[12px]"
                  />
                  <Button
                    onClick={handleChangeCanvasSize}
                    className="bg-main-black px-4 py-2 text-white rounded">
                    {t('Set Canvas Size')}
                  </Button>
                </div>
              </div>

              <DrawingCanvas
                image={image}
                drawings={drawings}
                currentDrawing={currentDrawing}
                setCurrentDrawing={setCurrentDrawing}
                canvasHeight={canvasSize.height}
                canvasWidth={canvasSize.width}
                drawLines={drawLines}
                ref={canvasRef}
                isClose={isClose}
                isDrawingEnabled={isDrawingEnabled} // Pass the drawing mode state
              />

              <div className="flex items-center gap-6 my-10">
                {isDrawingEnabled && (
                  <>
                    <Button
                      onClick={() => {
                        handleSubmit();
                        handleSaveDrawing(values, errors, handleReset);
                      }}
                      type="submit"
                      className="bg-accent-green text-white rounded">
                      {t('Save Drawing')}
                    </Button>
                    <Button onClick={handleReset} className="bg-main-black text-white rounded">
                      {t('Reset')}
                    </Button>
                    <Button
                      onClick={handleRemoveLastDrawing}
                      className="bg-main-black text-white rounded">
                      {t('Remove Last Added Section')}
                    </Button>
                  </>
                )}
              </div>
              <Flex my={'lg'} gap={'lg'}>
                <Button onClick={() => {}}>{t('Отменить')}</Button>
                <Button
                  onClick={() => {
                    setActiveContent(1);
                    setSearchParams({ tab: 1 });
                  }}
                  className="bg-accent-green text-white rounded">
                  {t('Перейти')}
                </Button>
              </Flex>
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        show={showModal}
        onConfirm={handleConfirmChangeSize}
        onCancel={handleCancelChangeSize}
      />
    </ContentLayout>
  );
};

export default HallSectionWrapper;

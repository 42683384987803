import { Accordion, Box, Button as SButton, Flex, Menu, Text } from '@mantine/core';
import { Form, Formik } from 'formik';
import { get, isEqual } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import Button from '../../components/button';
import ContentLayout from '../../components/content-layout';

import FormInput from '../../components/input/FormInput';
import SelectInput from '../../components/select/select-input';
import { Routes } from '../../constants/routes';
import { useGetAllQuery, usePostQuery, usePutQuery } from '../../hooks/query';

import { STATUS_OPTION } from '../categories/edit-category';
import Loader from '../../components/loader';

function CreateSeances() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { mutate, isLoading } = usePutQuery({
    listKeyId: 'template-sms'
  });
  const { id } = useParams();

  const { data, isLoading: isLoadingData } = useGetAllQuery({
    key: 'sms-template',
    url: 'template/sms/' + id
  });
  const updateSms = (formData) => {
    const submittingData = {
      ...get(data, 'data', {}),
      template: {
        uz: formData.uz,
        en: formData.en,
        ru: formData.ru
      },
      creator: formData.creator,
      placeholderNames: formData.placeholderNames
    };

    mutate(
      {
        url: '/template/sms/',
        attributes: submittingData
      },
      {
        onSuccess: ({ data }) => {
          navigate(Routes.SMS_TEMPLATE);
        },
        onError: () => {}
      }
    );
  };

  if (isLoadingData) {
    return <Loader />;
  }
  return (
    <ContentLayout breadcrumb={true}>
      <Formik
        initialValues={{
          uz: get(data, 'data.template.uz', ''),
          ru: get(data, 'data.template.ru', ''),
          en: get(data, 'data.template.en', ''),
          templateUz: get(data, 'data.template.uz', ''),
          templateRu: get(data, 'data.template.ru', ''),
          templateEn: get(data, 'data.template.en', ''),
          creator: get(data, 'data.creator', ''),
          status: get(data, 'data.status', '')
        }}
        enableReinitialize={true} // This allows the form to update when `data` changes
        onSubmit={updateSms}>
        {({ setFieldValue, values, errors, setFieldTouched }) => (
          <Form>
            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Название шаблона')}</p>
            <div className="flex items-center gap-6 my-6">
              <FormInput type="text" name="uz" label={t('Узбекский')} />
              <FormInput type="text" name="ru" label={t('Русский')} />
              <FormInput type="text" name="en" label={t('Английский')} />
            </div>

            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Содержания шаблона')}</p>

            <FormInput wFull type="text" name="templateUz" label={t('Узбекский')} />
            <FormInput wFull type="text" name="templateRu" label={t('Русский')} />
            <FormInput wFull type="text" name="templateEn" label={t('Английский')} />

            <p className="text-[20px] font-semibold my-6 text-gray-3">{t('Информация шаблона')}</p>

            <div className="flex items-center gap-6 my-6">
              <FormInput type="text" name="creator" label={t('creator')} />
              <SelectInput
                label={t('status')}
                onChange={(ev) => setFieldValue('status', ev?.value)}
                options={STATUS_OPTION(t)}
              />
            </div>

            <div className="flex items-center gap-6 mt-10">
              <Button className="bg-main-black">Отменить</Button>
              <Button type="submit" className="bg-accent-green">
                {t('Добавить')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ContentLayout>
  );
}

export default CreateSeances;

import { Text } from '@mantine/core';
import { Form, Formik } from 'formik';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../../../../components/button';
import FormInput from '../../../../../components/input/FormInput';
import Loader from '../../../../../components/loader';
import { Modal } from '../../../../../components/modal';
import SelectInput from '../../../../../components/select/select-input';
import Switch from '../../../../../components/switch/Switch';
import { useGetAllQuery } from '../../../../../hooks/query';
import { setHall } from '../../../../../store/slices/settingsSlice';
const STATUS_OPTIONTYPE = (t) => {
  return [
    { value: '1', label: t('зависимый') },
    { value: '2', label: t('независимый ') }
  ];
};
const STATUS_OPTIONSTATUS = (t) => {
  return [
    { value: true, label: <Text c="#088157">{t('Active')}</Text> },
    { value: false, label: <Text c="#C61F1F">{t('Deactive')}</Text> }
  ];
};

const validationSchema = Yup.object().shape({
  name_uz: Yup.string().required('Uzbek name is required'),
  name_ru: Yup.string().required('Russian name is required'),
  name_en: Yup.string().required('English name is required'),
  x_cordinate: Yup.number()
    .required('X Coordinate is required')
    .min(0, 'Must be a positive number'),
  y_cordinate: Yup.number()
    .required('Y Coordinate is required')
    .min(0, 'Must be a positive number'),
  addiction: Yup.string().required('Addiction type is required'),
  addiction_merchant: Yup.string().required('Merchant is required'),
  status: Yup.boolean().required('Status is required'),
  address_uz: Yup.string().required('Uzbek address is required'),
  address_ru: Yup.string().required('Russian address is required'),
  address_en: Yup.string().required('English address is required'),
  lat: Yup.number()
    .required('Latitude is required')
    .min(-90, 'Must be between -90 and 90')
    .max(90, 'Must be between -90 and 90'),
  lon: Yup.number()
    .required('Longitude is required')
    .min(-180, 'Must be between -180 and 180')
    .max(180, 'Must be between -180 and 180')
});
const BaseForm = ({ setActiveContent, activeContent }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const hall = useSelector((state) => state.settings.hall);
  const [hasFigure, setHasFigure] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { data: merchantList, isLoading: isLoadingMerchant } = useGetAllQuery({
    key: 'merchant',
    url: 'admin/merchant'
  });
  const createHall = (data) => {
    const newHall = {
      name: {
        uz: get(data, 'name_uz'),
        ru: get(data, 'name_ru'),
        en: get(data, 'name_en')
      },
      isActive: get(data, 'status'),
      merchantId: get(data, 'addiction_merchant'),
      address: {
        name: {
          uz: get(data, 'address_uz'),
          ru: get(data, 'address_ru'),
          en: get(data, 'address_en')
        },
        lat: get(data, 'lat'),
        lon: get(data, 'lon')
      },
      hall: {
        width: get(data, 'x_cordinate'),
        height: get(data, 'y_cordinate'),
        backgroundUrl: '',
        sections: []
      }
    };

    dispatch(setHall(newHall));
    setOpenModal(true);
  };

  const saveHandler = () => {
    setOpenModal(false);
    if (hasFigure) {
      setActiveContent(2);
      setSearchParams({ tab: 2 });
      return;
    }
    setActiveContent(3);
    setSearchParams({ tab: 3 });
  };
  if (isLoadingMerchant) {
    return <Loader />;
  }

  return (
    <>
      <Formik
        initialValues={{
          name_uz: get(hall, 'name.uz', ''),
          name_ru: get(hall, 'name.ru', ''),
          name_en: get(hall, 'name.en', ''),
          lat: get(hall, 'address.lat', null),
          lon: get(hall, 'address.lon', null),
          y_cordinate: get(hall, 'hall.height', null),
          x_cordinate: get(hall, 'hall.width', null),
          address_uz: get(hall, 'address.name.uz', ''),
          address_ru: get(hall, 'address.name.ru', ''),
          address_en: get(hall, 'address.name.en', ''),
          addiction: null,
          addiction_merchant: get(hall, 'merchantId', ''),
          status: get(hall, 'isActive', false)
        }}
        validationSchema={validationSchema}
        onSubmit={createHall}>
        {({ setFieldValue, values, errors, touched, setFieldTouched }) => {
          return (
            <Form>
              <p className="text-[20px] font-semibold mt-8 text-gray-2">{t('Названия зала')}</p>
              <div className="flex gap-6 flex-wrap mt-6">
                <FormInput name="name_uz" label={t('Узбекский')} />
                <FormInput name="name_ru" label={t('Русский')} />
                <FormInput name="name_en" label={t('Английский')} />
              </div>
              <p className="text-[20px] font-semibold mt-8 text-gray-2">{t('Статусы')}</p>
              <div className="flex gap-6 flex-wrap mt-6">
                <div className="min-w-[380px]">
                  <SelectInput
                    onChange={(ev) => {
                      setFieldTouched('addiction', true);
                      setFieldValue('addiction', ev?.value);
                    }}
                    name="addiction"
                    options={STATUS_OPTIONTYPE(t)}
                    label={t('Зависимость')}
                  />
                  {!touched.addiction && errors.addiction && (
                    <p className="text-[14px] text-main">{t('Addiction is required')}</p>
                  )}
                </div>
                <div className="min-w-[380px]">
                  <SelectInput
                    onChange={(ev) => {
                      setFieldTouched('addiction_merchant', true);
                      setFieldValue('addiction_merchant', ev?.value);
                    }}
                    name="addiction_merchant"
                    options={get(merchantList, 'data', []).map((merchant) => {
                      return {
                        label: get(merchant, 'name.uz'),
                        value: get(merchant, 'id')
                      };
                    })}
                    label={t('Зависимый мерчант')}
                  />
                  {errors.addiction_merchant && !touched.addiction_merchant && (
                    <p className="text-[14px] text-main">{t('addiction_merchant is required')}</p>
                  )}
                </div>
                <div className="min-w-[380px]">
                  <SelectInput
                    onChange={(ev) => {
                      setFieldTouched('status', true);
                      setFieldValue('status', ev?.value);
                    }}
                    name="status"
                    options={STATUS_OPTIONSTATUS(t)}
                    label={t('Статус')}
                  />
                  {errors.status && !touched.status && (
                    <p className="text-[14px] text-main">{t('status is required')}</p>
                  )}
                </div>
              </div>
              <div className="bg-accent-gray w-full h-[1px] my-8"></div>
              <p className="text-[20px] font-semibold  text-gray-2">{t('Адрес')}</p>
              <div className="flex flex-col gap-6 flex-wrap mt-6 max-w-[1190px]">
                <FormInput name="address_uz" label={t('Узбекский')} wFull />
                <FormInput name="address_ru" label={t('Русский')} wFull />
                <FormInput name="address_en" label={t('Английский')} wFull />
                <div className="flex gap-6">
                  <FormInput name="lat" label={t('LAT')} />
                  <FormInput name="lon" label={t('LONG')} />
                </div>
              </div>
              <div className="bg-accent-gray w-full h-[1px] my-8"></div>
              <div className="flex items-center justify-between">
                <p className="text-[20px] font-semibold  text-gray-2">{t('Карта зала')}</p>
              </div>

              <div className="flex gap-6 mt-8">
                <FormInput name="x_cordinate" label={t('Координата X')} />
                <FormInput name="y_cordinate" label={t('Координата Y')} />
              </div>
              {/* <div className="w-full h-[400px] rounded-[8px] bg-main-black mt-6"></div> */}
              <div className="flex items-center gap-6 mt-8">
                <Button className="bg-main-black">{t('Отменить')}</Button>
                <Button type="submit" className="bg-main-green">
                  {t('Сохранить')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal size="xs" open={openModal} onHandleChange={() => setOpenModal(false)}>
        <h3 className="text-[20px] font-semibold mt-4 text-gray-2">{t('Создать карта зала')}</h3>
        <p className="text-[20px] font-semibold my-4 text-gray-3">{t('Зал будет с секциями ?')}</p>
        <Switch on={hasFigure} onClick={() => setHasFigure((prev) => !prev)} />
        <div className="flex items-center gap-6 mt-10 w-full">
          <Button onClick={() => setOpenModal(false)} className="bg-main-black w-full">
            {t('Отменить')}
          </Button>
          <Button onClick={saveHandler} className="bg-main-green w-full">
            {t('Создать')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default BaseForm;

import { Accordion, Box, Flex, Menu, Text } from '@mantine/core';
import { Option, Select } from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ArrowDownIcon from '../../../assets/icons/arrow-down-icon';
import ArrowUpIcon from '../../../assets/icons/arrow-up-icon';
import Button from '../../../components/button';
import ContentLayout from '../../../components/content-layout';
import FileInput from '../../../components/input/file-input';
import FileInputImage from '../../../components/input/file-input-image';
import FormInput from '../../../components/input/FormInput';
import ToastSuccess from '../../../components/notification/SuccessNotification';
import MainSelect from '../../../components/select/select';
import SelectInput from '../../../components/select/select-input';
import Switch from '../../../components/switch/Switch';
import { Routes } from '../../../constants/routes';
import { usePostQuery } from '../../../hooks/query';
import elementCreate from '../../../utils/elementCreate';

export const STATUS_OPTIONTYPE = (t) => {
  return [
    { value: 'INACTIVE', label: t('0 - scene') },
    { value: 'SEAT', label: t('1 - seat') },
    { value: 'DUAL', label: t('2 - Duo') },
    { value: 'MULTIPLE', label: t('Multiple') },
    { value: 'TEXT', label: t('Text') }
  ];
};

export const STATUS_OPTION = (t) => [
  {
    value: 'circle',
    label: (
      <Flex gap={'lg'} align={'center'}>
        <div className={elementCreate('circle') + ' w-12 h-12'}></div>
        <span> {t('Circle')}</span>
      </Flex>
    )
  },
  {
    value: 'square',
    label: (
      <Flex gap={'lg'} align={'center'}>
        <div className={elementCreate('square') + ' w-12 h-12'}></div>
        <span> {t('square')}</span>
      </Flex>
    )
  },
  {
    value: 'rectangle',
    label: (
      <Flex gap={'lg'} align={'center'}>
        <div className={elementCreate('rectangle') + ' w-[44px] h-[32px]'}></div>
        <span> {t('rectangle')}</span>
      </Flex>
    )
  },
  {
    value: 'oval',
    label: (
      <Flex gap={'lg'} align={'center'}>
        <div className={elementCreate('oval') + ' w-[44px] h-[34px]'}></div>
        <span> {t('oval')}</span>
      </Flex>
    )
  },
  {
    value: 'triangle',
    label: (
      <Flex gap={'lg'} align={'center'}>
        <div className={elementCreate('triangle')}></div>
        <span> {t('triangle')}</span>
      </Flex>
    )
  },
  {
    value: 'other',
    label: (
      <Flex gap={'lg'} align={'center'}>
        <div className={elementCreate('other')}></div>
        <span> {t('other')}</span>
      </Flex>
    )
  }
];

function CreateHallElement() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { mutate, isLoading } = usePostQuery({
    listKeyId: 'product-list'
  });
  const [openType, setOpenType] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const addCategory = (data) => {
    const submittingData = {
      figure: get(data, 'figure.value'),
      isScalable: get(data, 'isScalable'),
      type: get(data, 'type'),
      name: {
        uz: data?.nameUz,
        ru: data?.nameRu,
        en: data?.nameEn
      },
      width: data?.elementWidth,
      height: data?.elementHeight
    };

    mutate(
      {
        url: '/admin/halls/elements',
        attributes: submittingData
      },
      {
        onSuccess: ({ data }) => {
          ToastSuccess('Category successfully created');
          navigate(Routes.ELEMENTS);
        },
        onError: () => {}
      }
    );
  };

  return (
    <ContentLayout breadcrumb={true}>
      <Formik
        initialValues={{
          figure: {
            label: (
              <Flex gap={'lg'} align={'center'}>
                <div className={elementCreate('circle') + ' w-12 h-12'}></div>
                <span> {t('Circle')}</span>
              </Flex>
            ),
            value: 'circle'
          },
          elementSize: true,
          type: ''
        }}
        onSubmit={addCategory}>
        {({ setFieldValue, values }) => (
          <Form>
            <p className="text-[20px] font-semibold mt-12 text-gray-2">
              {t('Добавление элементы')}
            </p>
            <div className="flex gap-6 flex-wrap mt-6">
              <div>
                <p className="input-label">{t('element_type')}</p>
                <div className="flex w-[380px] h-[64px] px-5 items-center justify-between bg-main-black rounded-[12px]">
                  <p className="text-[16px] font-semibold">{t('figure')}</p>
                  <Switch
                    on={values?.element_type}
                    onClick={() => setFieldValue('element_type', !values?.element_type)}
                  />
                </div>
              </div>

              {values.element_type ? (
                <Box mt={'20px'}>
                  <Text c={'#777777'} className="">
                    {t('Тип элемента')}
                  </Text>
                  <Menu opened={openStatus} onChange={setOpenStatus} shadow="md" width={200}>
                    <Menu.Target>
                      <Flex
                        maw={'200px'}
                        className="cursor-pointer"
                        p={'12px'}
                        justify={'space-between'}
                        align={'center'}
                        bg={'#111111'}
                        style={{
                          borderRadius: '12px'
                        }}>
                        <Flex gap={'lg'}>{values.figure.label}</Flex>

                        {openStatus ? (
                          <ArrowUpIcon color={'#fff'} />
                        ) : (
                          <ArrowDownIcon color="#fff" />
                        )}
                      </Flex>
                    </Menu.Target>

                    <Menu.Dropdown bg={'#111111'} w={'300px'}>
                      {STATUS_OPTION(t).map((val) => (
                        <Menu.Item p={'20px'}>
                          <Box onClick={() => setFieldValue('figure', val)}>
                            <Text>{get(val, 'label')}</Text>
                          </Box>
                        </Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                </Box>
              ) : (
              
                <FileInput
                  onChange={(res) => setFieldValue('figure', get(res, 'data.url'))}
                  id="icon"
                  label={t('icon')}
                />
              )}
            </div>
            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Названия элемента')}</p>
            <div className="flex gap-6 flex-wrap mt-6">
              <FormInput name="nameUz" label={t('name_uz')} />
              <FormInput name="nameRu" label={t('name_ru')} />
              <FormInput name="nameEn" label={t('name_en')} />
            </div>
            <p className="text-[20px] font-semibold mt-12 text-gray-2">
              {t('Характеристики элемента')}
            </p>
            <div className="flex gap-6 flex-wrap mt-6">
              <FormInput name="elementWidth" label={t('element_width')} />
              <FormInput name="elementHeight" label={t('element_height')} />
              <div>
                <p className="input-label">{t('element_size')}</p>
                <div className="flex w-[380px] h-[64px] px-5 items-center justify-between bg-main-black rounded-[12px]">
                  <p className="text-[16px] font-semibold">{t('isScalable')}</p>
                  <Switch
                    on={values?.isScalable}
                    onClick={() => setFieldValue('isScalable', !values?.isScalable)}
                  />
                </div>
              </div>
            </div>
            {/* <SelectInput
              className={'mt-5'}
              label={t('Type')}
              onChange={(ev) => setFieldValue('type', ev?.value)}
              options={STATUS_OPTIONTYPE(t)}
            /> */}
            <Box mt={'20px'}>
              <Text c={'#777777'} className="">
                {t('Тип элемента')}
              </Text>
              <Menu opened={openType} onChange={setOpenType} shadow="md" width={200}>
                <Menu.Target>
                  <Flex
                    maw={'200px'}
                    className="cursor-pointer"
                    p={'12px'}
                    justify={'space-between'}
                    align={'center'}
                    bg={'#222222'}
                    style={{
                      borderRadius: '12px'
                    }}>
                    <Flex gap={'lg'}>
                      <Text>{t(values.type)}</Text>
                    </Flex>

                    {openType ? <ArrowUpIcon color={'#fff'} /> : <ArrowDownIcon color="#fff" />}
                  </Flex>
                </Menu.Target>

                <Menu.Dropdown w={'300px'}>
                  {STATUS_OPTIONTYPE(t).map((val) => (
                    <Menu.Item p={'20px'}>
                      <Box onClick={() => setFieldValue('type', val.value)}>
                        <Text>{get(val, 'label')}</Text>
                      </Box>
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            </Box>
            <div className="flex items-center gap-6 mt-10">
              <Button className="bg-main-black">Отменить</Button>
              <Button type="submit" className="bg-accent-green">
                {t('Добавить')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ContentLayout>
  );
}

export default CreateHallElement;

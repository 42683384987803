import React from 'react';

const ConfirmationModal = ({
  show,
  onConfirm,
  onCancel,
  title = 'Decreasing the size will remove existing data. Continue?'
}) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
      <div className="bg-[#131313] p-6 rounded shadow-lg z-50">
        <h2 className="text-lg font-bold mb-4">Confirm </h2>
        <p className="mb-4">{title}</p>
        <div className="flex justify-end">
          <button onClick={onCancel} className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2">
            Cancel
          </button>
          <button onClick={onConfirm} className="bg-red-500 text-white px-4 py-2 rounded">
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

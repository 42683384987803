import { Input } from '@material-tailwind/react';
import cx from 'classnames';
import { useField } from 'formik';
import React, { useState } from 'react';

function FormInput({ label, wFull = false, className, withoutForm = false, ...props }) {
  const [isFocus, setIsFocus] = useState(false);
  const [field, meta] = useField(!withoutForm ? props : null);

  return (
    <div className={cx('w-[380px]', { 'w-full': wFull })}>
      {label && <p className="input-label">{label}</p>}

      <Input
        value={field?.value ? field?.value : ''}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        labelProps={{
          className: 'hidden'
        }}
        containerProps={{ className: 'h-[64px]' }}
        className={cx(
          '!h-[56px] !rounded-lg !bg-main-black text-white px-4  !border-[transparent] outline-none autofill:bg-main-black',
          className,
          {
            '!border-main-black': isFocus,
            '!border-main': meta.touched && meta.error
          }
        )}
        {...field}
        {...props}
      />
      {!withoutForm && meta.touched && meta.error ? (
        <div className="text-[14px] text-main">{meta.error}</div>
      ) : null}
    </div>
  );
}

export default FormInput;

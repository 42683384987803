import React from 'react';
import ContentLayout from "../../components/content-layout";

function Home(props) {

    return (
        <ContentLayout title='Home'>
            <div className='w-full h-[650px] bg-main-black rounded-[8px]'>

            </div>
        </ContentLayout>
    );
}

export default Home;

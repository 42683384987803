import React from 'react';

function RuFlagIcon({ size = 24, color = '#A1A1A1', className }) {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_757_2317)">
    <mask id="mask0_757_2317"  style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width={size} height={size}>
    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_757_2317)">
    <path d="M24 7.96875V16.0312L12 17.5312L0 16.0312V7.96875L12 6.46875L24 7.96875Z" fill="#0052B4"/>
    <path d="M24 0V7.96875H0V0H24Z" fill="#EEEEEE"/>
    <path d="M24 16.0312V24H0V16.0312H24Z" fill="#D80027"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_757_2317">
    <rect width={size} height={size} fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  );
}

export default RuFlagIcon;

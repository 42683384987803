import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';
import { Menu, Group } from '@mantine/core';
import { LuChevronDown } from 'react-icons/lu';
import UzFlagIcon from '../../assets/icons/uz-flag-icon';
import RuFlagIcon from '../../assets/icons/ru-flag-icon';
import EnFlagIcon from '../../assets/icons/en-flag-icon';

function Root() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const languages = [
    { code: 'en', label: 'English', flag: <EnFlagIcon /> },
    { code: 'ru', label: 'Русский', flag: <RuFlagIcon /> },
    { code: 'uz', label: 'O‘zbek', flag: <UzFlagIcon /> }
  ];

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const getCurrentLangInfo = () => languages.find((lang) => lang.code === currentLang);

  const currentLangInfo = getCurrentLangInfo();

  const icon =
    currentLang === 'uz' ? <UzFlagIcon /> : currentLang === 'ru' ? <RuFlagIcon /> : <EnFlagIcon />;

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1">
        <nav className="w-full h-[80px] bg-main-black flex items-center justify-end px-8 gap-4">
          <Menu shadow="md" width={150} withArrow>
            <Menu.Target>
              <Group gap={"8px"} px={"12px"} py={"14px"} className="bg-accent-gray rounded-xl cursor-pointer">
                {icon}
                <span>{t(currentLangInfo?.code)}</span>
                <LuChevronDown size={16} />
              </Group>
            </Menu.Target>

            <Menu.Dropdown>
              {languages.map((lang) => (
                <Menu.Item key={lang.code} onClick={() => changeLanguage(lang.code)}>
                  <Group gap={"8px"} className="cursor-pointer">
                    {lang.flag}
                    {t(lang.label)}
                  </Group>
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </nav>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Root;

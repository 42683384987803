import cx from 'classnames';
import React from 'react';

const BreadCump = ({ activeContent, setSearchParams, setActiveContent }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center gap-2">
        <div className={cx('py-[6px] rounded-[16px]', {})}>
          <p className={cx('font-[500] text-gray-3')}>{'home'}</p>
        </div>
        <span className="text-[20px] text-gray-3">/</span>
      </div>
      <div className="flex items-center gap-2 cursor-pointer">
        <div className={cx('py-[6px] rounded-[16px]', {})}>
          <p
            onClick={() => {
              if (activeContent === 3) {
                setSearchParams({ tab: 2 });
                setActiveContent(2);
              }
              if (activeContent === 2) {
                setSearchParams({ tab: 1 });
                setActiveContent(1);
              }
            }}
            className={cx('font-[500] text-gray-3')}>
            {activeContent === 3
              ? 'Create section'
              : activeContent === 2
                ? 'Create hall'
                : 'Create map'}
          </p>
        </div>
        {activeContent !== 1 && <span className="text-[20px] text-gray-3">/</span>}
      </div>
      {activeContent !== 1 && (
        <div className="flex items-center gap-2">
          <div className={cx('py-[6px] rounded-[16px] bg-main-black px-3')}>
            <p className={cx('font-[500] !text-main')}>
              {activeContent === 2 ? 'Create section' : 'Create map'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BreadCump;

function elementCreate(element) {
  switch (element) {
    case 'square':
      return 'w-16 h-16 bg-[#2d2d2d] rounded-[4px]';

    case 'circle':
      return 'w-16 h-16 bg-[#2d2d2d] rounded-[50%]';

    case 'rectangle':
      return 'w-32 h-16 bg-[#2d2d2d] rounded-[4px]';

    case 'oval':
      return 'w-[99px] h-[72px] bg-[#2d2d2d] rounded-[132px]';

    case 'triangle':
      return 'w-16 h-16 bg-[#2d2d2d] rounded-[4px]';

    case 'other':
      return '';

    default:
      return '';
  }
}

export default elementCreate;

import React from 'react';
import ContentLayout from "../../components/content-layout";

function UserItem(props) {
    return (
        <ContentLayout breadcrumb={true}>
            dsasd
        </ContentLayout>
    );
}

export default UserItem;

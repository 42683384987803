import React from 'react';
import {useTranslation} from "react-i18next";
import StatusTag from "../../../components/status/StatusTag";
import ContentLayout from "../../../components/content-layout";
import Button from "../../../components/button";
import {Routes} from "../../../constants/routes";
import Table from "../../../components/table";
import { useGetAllQuery } from '../../../hooks/query';
import { get } from 'lodash';
import Loader from '../../../components/loader';

function HallsScreen() {
    const {t} = useTranslation();


    const { data, isLoading } = useGetAllQuery({
        key: 'halls',
        url: 'admin/halls'
      });
    const content = [
        {header: t('merchant'), key: 'merchant'},
        {header: t('addiction'), key: 'addiction'},
        {header: t('hallName'), key: 'name',renderCell: (row) => <p className=''>{get(row,"name.uz")}</p>},
        {header: t('address'), key: 'address',renderCell: (row) => <p className=''>{get(row,"address.name.uz")}</p>},
        {
            header: t('Isactive'),
            width: 120,
            key: 'status',
            renderCell: (row) => (
                <StatusTag value={get(row,"isActive") ?  'active' : "inactive"} variant={get(row,"isActive") ?  'active' : "inactive"}/>
            )
        },
        {
            header: t('date'),
            key: 'date',
            width: 100,
            renderCell: (row) => <p className='text-main'>{row.date}</p>
        },
    ]
    

    if(isLoading){
        return <Loader/>
    }

    return (
        <ContentLayout
            title={t('halls')}
            loader={false}
            rightAction={
                <Button link={Routes.CREATE_HALL} className='bg-accent-green'>
                    {t('create')}
                </Button>}>

            <Table
                withOrder={true}
                content={content}
                list={get(data,"data",[])}
            />
        </ContentLayout>
    );
}

export default HallsScreen;

import { IconButton } from '@material-tailwind/react';
import axios from 'axios';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import Button from '../../../components/button';
import ContentLayout from '../../../components/content-layout';
import ToastSuccess from '../../../components/notification/SuccessNotification';
import Table from '../../../components/table';
import { Routes } from '../../../constants/routes';
import { useDeleteQuery, useGetAllQuery } from '../../../hooks/query';
import useGetImages from '../../../hooks/useGetImages/useGetImages';
import { httpClient } from '../../../services';
import elementCreate from '../../../utils/elementCreate';

function HallsElements() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const content = [
    {
      header: t('Элемент'),
      key: 'figure',
      width: 200,
      renderCell: (row) => {
        const figureUrl = get(row, 'figure');
        if (figureUrl && figureUrl.includes('api.dev.biletick.uz')) {
          if (base64Images[figureUrl]) {
            return <img width={120} src={base64Images[figureUrl]} alt={get(row, 'name.uz')} />;
          } else {
            return <div></div>;
          }
        }
        return <div className={elementCreate(figureUrl)} />;
      }
    },
    { header: t('Название элемента'), key: 'name[uz]', renderCell: (row) => row.name?.uz },
    { header: t('Ширина'), key: 'width' },
    { header: t('Высота'), key: 'height' },
    { header: t('Объект элемента'), key: 'type' },
    {
      header: t('Actions'),
      key: '',
      renderCell: (row) => (
        <div>
          <IconButton variant="text" size="sm" onClick={() => handleDelete(get(row, 'id'))}>
            <RiDeleteBin6Fill color="red" size={24} />
          </IconButton>
        </div>
      )
    }
  ];

  const { mutate, isLoading: deleteLoading } = useDeleteQuery({
    listKeyId: 'hall-elements'
  });

  const handleDelete = (id) => {
    mutate(
      {
        url: '/admin/halls/elements/' + id
      },
      {
        onSuccess: ({ data }) => {
          ToastSuccess('Element successfully deleted');
        },
        onError: () => {}
      }
    );
  };

  const { data, isLoading } = useGetAllQuery({
    key: 'hall-elements',
    url: 'admin/halls/elements'
  });
  const elements = get(data, 'data', []);
  const { base64Images } = useGetImages({ data: elements });
  return (
    <ContentLayout
      title={t('hall_elements')}
      loader={isLoading || deleteLoading}
      rightAction={
        <Button link={Routes.CREATE_ELEMENTS} className="bg-accent-green">
          {t('create')}
        </Button>
      }>
      <Table hasEdit={false} withOrder={true} content={content} list={elements} />
    </ContentLayout>
  );
}

export default HallsElements;

import { Accordion, Box, Button, Flex, Menu, Select, Switch, Text } from '@mantine/core';
import { t } from 'i18next';
import { get, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import ArrowDownIcon from '../../../../../assets/icons/arrow-down-icon';
import ArrowUpIcon from '../../../../../assets/icons/arrow-up-icon';
import PenSectionIcon from '../../../../../assets/icons/pen-section-icon';
import FileInput from '../../../../../components/input/file-input';
import useGetImage from '../../../../../hooks/useGetImages/useGetImage';

const ElementsList = ({
  setImage,
  setCanvasSize,
  setIsDrawingEnabled,
  isDrawingEnabled,
  element,
  setElement,
  url,
  setUrl,
  drawings,
  setFieldValue,
  errors,
  touched,
  values,
  setActiveContent,
  setSearchParams
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const base64Image = useGetImage(url);
  const [colorDropdownOpened, setColorDropdownOpened] = useState(false);
  const [color, setColor] = useState({
    name: 'red',
    color: '#C61F1F'
  });

  useEffect(() => {
    if (base64Image) {
      setImage(base64Image);
    }
  }, [base64Image, setImage]);

  return (
    <div className=" red-scrollbar w-[292px] flex flex-col gap-4  max-h-[80vh] overflow-auto">
      <Button.Group bg={'#111111'}>
        <Button
          onClick={() => setActiveTab(1)}
          fullWidth
          styles={{
            root: {
              border: 'none',
              borderRadius: isEqual(activeTab, 1) ? '12px' : '0'
            },
            label: {
              color: isEqual(activeTab, 1) ? '#C61F1F' : '#fff'
            }
          }}
          bg={isEqual(activeTab, 1) ? '#1D1D1D' : 'transparent'}
          variant="default">
          {t('Объекты')}
        </Button>
        <Button
          onClick={() => setActiveTab(2)}
          fullWidth
          styles={{
            root: {
              border: 'none',
              borderRadius: isEqual(activeTab, 2) ? '12px' : '0'
            },
            label: {
              color: isEqual(activeTab, 2) ? '#C61F1F' : '#fff'
            }
          }}
          bg={isEqual(activeTab, 2) ? '#1D1D1D' : 'transparent'}
          variant="default">
          {t('Список')}
        </Button>
      </Button.Group>
      {isEqual(activeTab, 1) ? (
        <>
          <Accordion
            multiple={true}
            defaultValue={['image', 'section']}
            styles={{
              item: {
                borderBottom: 'none'
              }
            }}>
            <Accordion.Item key={'image'} value={'image'}>
              <Accordion.Control>{t('Добавить картинку (BG)')}</Accordion.Control>
              <Accordion.Panel>
                <FileInput
                  setSize={setCanvasSize}
                  className=" h-full"
                  onChange={(res) => {
                    setUrl(get(res, 'data.url'));
                    setFieldValue('figure', get(res, 'data.url'));
                  }}
                  id="icon"
                  label={t('Background')}
                />
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item key={'section'} value={'section'}>
              <Accordion.Control>{t('Добавить секции')}</Accordion.Control>
              <Accordion.Panel>
                <Box style={{ borderRadius: '12px' }} py={'20px'} bg={'#111111'}>
                  <Box
                    onClick={() => setIsDrawingEnabled((prev) => !prev)}
                    bg={isDrawingEnabled ? '#C61F1F' : '#1D1D1D'}
                    style={{ borderRadius: '8px' }}
                    p={'20px'}
                    mx={'auto'}
                    w={'64px'}>
                    <PenSectionIcon />
                  </Box>
                  <Text
                    style={{
                      borderBottom: '1px solid #1D1D1D'
                    }}
                    pb={'20px'}
                    mt={'18px'}
                    ta={'center'}
                    size="14px">
                    {t('Секция – Пентул')}
                  </Text>
                  <Box mt={'12px'} p={'20px'}>
                    <Box mb={'12px'}>
                      <Text pb={'6px'} size="14px" c="#777777">
                        {t('Названия Узбекская')}
                      </Text>
                      <input
                        value={element.uz}
                        placeholder=""
                        onChange={(e) => {
                          setElement((prev) => {
                            return {
                              ...prev,
                              uz: e.target.value
                            };
                          });
                          setFieldValue('uz', e.target.value);
                        }}
                        className={` border  p-4   outline-none rounded-xl w-full bg-[#222222] ${errors.uz && touched.uz ? 'border-red-500' : 'border-transparent'}`}
                      />
                      {errors.uz && touched.uz && (
                        <div className="error text-[#c61f1f]">{errors.uz}</div>
                      )}
                    </Box>

                    <Box mb={'12px'}>
                      <Text pb={'6px'} size="14px" c="#777777">
                        {t('Названия Русская')}
                      </Text>
                      <input
                        value={element.ru}
                        onChange={(e) => {
                          setElement((prev) => {
                            return {
                              ...prev,
                              ru: e.target.value
                            };
                          });
                          setFieldValue('ru', e.target.value);
                        }}
                        placeholder=""
                        className={` border  p-4   outline-none rounded-xl w-full bg-[#222222] ${errors.ru && touched.ru ? 'border-red-500' : 'border-transparent'}`}
                      />
                      {errors.ru && touched.ru && (
                        <div className="error text-[#c61f1f]">{errors.ru}</div>
                      )}
                    </Box>
                    <Box mb={'12px'}>
                      <Text pb={'6px'} size="14px" c="#777777">
                        {t('Названия Английская')}
                      </Text>
                      <input
                        value={element.en}
                        placeholder=""
                        onChange={(e) => {
                          setElement((prev) => {
                            return {
                              ...prev,
                              en: e.target.value
                            };
                          });
                          setFieldValue('en', e.target.value);
                        }}
                        className={` border  p-4   outline-none rounded-xl w-full bg-[#222222] ${errors.en && touched.en ? 'border-red-500' : 'border-transparent'}`}
                      />
                      {errors.en && touched.en && (
                        <div className="error text-[#c61f1f]">{errors.en}</div>
                      )}
                    </Box>

                    <div className="flex items-center gap-6 my-10 w-full">
                      <Box>
                        <Text pb={'6px'} size="14px" c="#777777">
                          {t('Угол текста')}
                        </Text>
                        <input
                          value={element.rotate}
                          type="number"
                          placeholder=""
                          onChange={(e) => {
                            setElement((prev) => {
                              return {
                                ...prev,
                                rotate: e.target.value
                              };
                            });
                            setFieldValue('rotate', e.target.value);
                          }}
                          className={` border  p-4   outline-none rounded-xl w-full bg-[#222222] ${errors.rotate && touched.rotate ? 'border-red-500' : 'border-transparent'}`}
                        />
                        {errors.rotate && touched.rotate && (
                          <div className="error text-[#c61f1f]">{errors.rotate}</div>
                        )}
                      </Box>
                      <Box>
                        <Text pb={'6px'} size="14px" c="#777777">
                          {t('Активность')}
                        </Text>
                        <Box className="border-none p-4 rounded-xl w-full bg-[#222222]">
                          <Switch
                            value={!!element.isActive}
                            onChange={(e) => {
                              setElement((prev) => {
                                return {
                                  ...prev,
                                  isActive: e.currentTarget?.checked || false
                                };
                              });
                              setFieldValue('isActive', e.currentTarget?.checked || false);
                            }}
                            color="#C61F1F"
                          />
                        </Box>
                      </Box>
                    </div>
                    <Box mb={'12px'}>
                      <Text pb={'6px'} size="14px" c="#777777">
                        {t('Выбор цвета')}
                      </Text>
                      <Menu
                        opened={colorDropdownOpened}
                        onChange={setColorDropdownOpened}
                        shadow="md"
                        width={200}>
                        <Menu.Target>
                          <Flex
                            className="cursor-pointer"
                            p={'12px'}
                            justify={'space-between'}
                            align={'center'}
                            bg={'#222222'}
                            style={{
                              borderRadius: '12px'
                            }}>
                            <Flex gap={'lg'}>
                              <Box
                                w={'32px'}
                                h={'32px'}
                                bg={color.color}
                                style={{ borderRadius: '8px' }}>
                                {' '}
                              </Box>
                              <Text>{t(color.name)}</Text>
                            </Flex>

                            {colorDropdownOpened ? (
                              <ArrowUpIcon color={'#fff'} />
                            ) : (
                              <ArrowDownIcon color="#fff" />
                            )}
                          </Flex>
                        </Menu.Target>

                        <Menu.Dropdown w={'300px'}>
                          <Menu.Item>
                            <Flex gap={'lg'} justify={'center'}>
                              <Box
                                w={'36px'}
                                h={'36px'}
                                bg={'#C61F1F'}
                                onClick={() => {
                                  setColor({
                                    name: 'red',
                                    color: '#C61F1F'
                                  });
                                  setElement((prev) => {
                                    return {
                                      ...prev,
                                      color: '#C61F1F'
                                    };
                                  });
                                  setFieldValue('color', '#C61F1F');
                                }}
                                style={{ borderRadius: '4px' }}></Box>
                              <Box
                                w={'36px'}
                                h={'36px'}
                                bg={'#E0A100'}
                                onClick={() => {
                                  setColor({
                                    name: 'yellow',
                                    color: '#E0A100'
                                  });
                                  setElement((prev) => {
                                    return {
                                      ...prev,
                                      color: '#E0A100'
                                    };
                                  });
                                  setFieldValue('color', '#E0A100');
                                }}
                                style={{ borderRadius: '4px' }}></Box>
                              <Box
                                w={'36px'}
                                h={'36px'}
                                bg={'#088157'}
                                onClick={() => {
                                  setColor({
                                    name: 'green',
                                    color: '#088157'
                                  });
                                  setElement((prev) => {
                                    return {
                                      ...prev,
                                      color: '#088157'
                                    };
                                  });
                                  setFieldValue('color', '#088157');
                                }}
                                style={{ borderRadius: '4px' }}></Box>
                              <Box
                                w={'36px'}
                                h={'36px'}
                                bg={'#009FD0'}
                                onClick={() => {
                                  setColor({
                                    name: 'skyblue',
                                    color: '#009FD0'
                                  });
                                  setElement((prev) => {
                                    return {
                                      ...prev,
                                      color: '#009FD0'
                                    };
                                  });

                                  setFieldValue('color', '#009FD0');
                                }}
                                style={{ borderRadius: '4px' }}></Box>
                              <Box
                                w={'36px'}
                                h={'36px'}
                                bg={'#4300D0'}
                                onClick={() => {
                                  setColor({
                                    name: 'blue',
                                    color: '#4300D0'
                                  });
                                  setElement((prev) => {
                                    return {
                                      ...prev,
                                      color: '#4300D0'
                                    };
                                  });
                                  setFieldValue('color', '#4300D0');
                                }}
                                style={{ borderRadius: '4px' }}></Box>
                            </Flex>
                            <Flex mt={'lg'} gap={'lg'} justify={'center'}>
                              <Box
                                w={'36px'}
                                h={'36px'}
                                bg={'#FFFFFF'}
                                onClick={() => {
                                  setColor({
                                    name: 'white',
                                    color: '#FFFFFF'
                                  });
                                  setElement((prev) => {
                                    return {
                                      ...prev,
                                      color: '#FFFFFF'
                                    };
                                  });
                                  setFieldValue('color', '#FFFFFF');
                                }}
                                style={{ borderRadius: '4px' }}></Box>
                              <Box
                                w={'36px'}
                                h={'36px'}
                                bg={'#111111'}
                                style={{ borderRadius: '4px' }}></Box>
                              <Box
                                w={'36px'}
                                h={'36px'}
                                bg={'#111111'}
                                style={{ borderRadius: '4px' }}></Box>
                              <Box
                                w={'36px'}
                                h={'36px'}
                                bg={'#111111'}
                                style={{ borderRadius: '4px' }}></Box>
                              <Box
                                w={'36px'}
                                h={'36px'}
                                bg={'#111111'}
                                style={{ borderRadius: '4px' }}></Box>
                            </Flex>
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    </Box>
                  </Box>
                </Box>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </>
      ) : (
        <Box>
          {drawings.map((section) => (
            <Box
              onClick={() => {
                setActiveContent(3);
                setSearchParams({ tab: 3, section: get(section, 'id') });
              }}
              className="cursor-pointer"
              mt={'lg'}
              bg={'#1D1D1D'}
              p={'lg'}>
              <Flex gap={'lg'}>
                <Box w={'48px'} h={'48px'} bg={get(section, 'color')}></Box>
                <Box>
                  <Text>{get(section, 'name.uz')}</Text>
                  <Text>{get(section, 'textRotateAngle')} degree</Text>
                </Box>
              </Flex>
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
};

export default ElementsList;

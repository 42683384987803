// import { useMutation, useQueryClient } from "@tanstack/react-query";
import { forEach, get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { httpClient } from '../../services';

const putRequest = (url, attributes, config = {}) => httpClient.put(url, attributes, config);
const usePutQuery = ({ hideSuccessToast = false, hideErrorToast = false, listKeyId = null }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate, isLoading, isError, error, isFetching } = useMutation(
    ({ url, attributes, config = {} }) => putRequest(url, attributes, config),
    {
      onSuccess: (data) => {
        if (!hideSuccessToast) {
          const message = get(data, 'data.message');
          // notifications.show({
          //     id: message,
          //     disallowClose: true,

          //     autoClose: 5000,
          //     title: t("SUCCESS"),
          //     message: t(message),
          //     color: "teal",
          //     icon: <IconCheck />,
          // });
        }
        if (listKeyId) {
          if (isArray(listKeyId)) {
            forEach(listKeyId, (val) => {
              queryClient.invalidateQueries(val);
            });
          } else {
            queryClient.invalidateQueries(listKeyId);
          }
        }
      },
      onError: (data) => {
        const message = get(data, 'response.data.message');
        if (!hideErrorToast) {
          // notifications.show({
          //     id: message,
          //     disallowClose: true,
          //     autoClose: 5000,
          //     title: t("ERROR"),
          //     message: t(message),
          //     color: "red",
          //     icon: <IconX />,
          //     className: "my-notification-class",
          //     loading: false,
          //     position: "top-right",
          // });
        }
      }
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    error,
    isFetching
  };
};
export default usePutQuery;

import { Box } from '@mantine/core';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { MapInteractionCSS } from 'react-map-interaction';
import 'tailwindcss/tailwind.css';

const DrawingCanvas = forwardRef(
  (
    {
      image,
      currentDrawing,
      setCurrentDrawing,
      canvasWidth,
      canvasHeight,
      drawings,
      drawLines,
      isClose,
      coordinates,
      isDrawingEnabled
    },
    ref
  ) => {
    const [zoomValue, setZoomValue] = useState({
      scale: 1,
      translation: { x: 0, y: 0 }
    });

    const isDrawing = useRef(false);

    useEffect(() => {
      handleResetZoom();
    }, [coordinates]);

    useEffect(() => {
      drawLines();
    }, [image, drawings, currentDrawing, canvasWidth, canvasHeight, isClose]);

    const handleResetZoom = () => setZoomValue({ scale: 1, translation: { x: 0, y: 0 } });

    const handleCanvasMouseDown = (e) => {
      if (!isDrawingEnabled) return;
      isDrawing.current = true;
      const { offsetX, offsetY } = e.nativeEvent;
      setCurrentDrawing((prev) => [...prev, { x: offsetX, y: offsetY }]);
    };

    const handleCanvasMouseMove = (e) => {
      if (!isDrawing.current) return;
      const { offsetX, offsetY } = e.nativeEvent;
      setCurrentDrawing((prev) => [...prev, { x: offsetX, y: offsetY }]);
    };

    const handleCanvasMouseUp = () => {
      if (!isDrawingEnabled) return;
      isDrawing.current = false;
    };

    const handleZoomChange = (value) => {
      if (
        value.scale === 1 &&
        value.translation.x > -300 &&
        value.translation.x < 300 &&
        value.translation.y > -300 &&
        value.translation.y < 300
      ) {
        setZoomValue(value);
      }
    };

    return (
      <div className="pt-5 relative flex flex-col items-center justify-start flex-1 overflow-auto">
        {/* <MapInteractionCSS value={zoomValue} onChange={handleZoomChange}> */}
        <Box mih={'50vh'} miw={'50vw'} className="border border-[#2D2D2D]">
          <canvas
            ref={ref}
            onMouseDown={handleCanvasMouseDown}
            onMouseMove={handleCanvasMouseMove}
            onMouseUp={handleCanvasMouseUp}
            onMouseLeave={handleCanvasMouseUp}
            width={canvasWidth}
            height={canvasHeight}
            className="border border-black"
          />
        </Box>
        {/* </MapInteractionCSS> */}
        <div className="zoom-controls flex gap-2 fixed bottom-4 right-4 p-4 bg-gray-800 rounded-lg shadow-lg">
          <button
            onClick={() => handleZoomChange({ ...zoomValue, scale: zoomValue.scale * 1.1 })}
            className="text-main bg-[#1D1D1D] rounded-xl hover:bg-blue-600 px-5 py-2 text-[24px] focus:outline-none focus:ring-2 focus:ring-blue-400">
            +
          </button>
          <button
            onClick={() => handleZoomChange({ ...zoomValue, scale: zoomValue.scale * 0.9 })}
            className="text-main bg-[#1D1D1D] hover:bg-blue-600 px-5 py-2 text-[24px] rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-400">
            -
          </button>
          <button
            onClick={handleResetZoom}
            className="text-main bg-[#1D1D1D] hover:bg-red-600 px-5 py-2 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-400">
            Reset
          </button>
        </div>
      </div>
    );
  }
);

export default DrawingCanvas;

import { IconButton } from '@material-tailwind/react';
import { get } from 'lodash';
import React  from 'react';
import { useTranslation } from 'react-i18next';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import Button from '../../components/button';
import ContentLayout from '../../components/content-layout';
import Table from '../../components/table';
import { Routes } from '../../constants/routes';
import { useDeleteQuery, useGetAllQuery } from '../../hooks/query';

import StatusTag from '../../components/status/StatusTag';
import moment from 'moment';
import { timeFormat } from '../../utils';

function SeanceList() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetAllQuery({
    key: 'hall-events',
    url: 'admin/sessions'
  });

  const elements = get(data, 'data', []);

  const content = [
    {
      header: t('Session–ID'),
      key: 'id',
      width: 200,
      renderCell: (row) => get(row, 'id')
    },
  
    { header: t('Название'), key: 'title[uz]', renderCell: (row) => row.title?.uz },
    { header: t('Язык'), key: 'screenLanguage' },

    { header: t('Дата добавление'), key: 'createdDate',
      renderCell: (row) => moment(row?.createdDate).format(timeFormat)

     },
    { header: t('Мерчант'), key: 'Мерчант' },
    { header: t('Зал'), key: 'Мерчант' },

    {
      header: t('Status'),
   
      key: 'status',
      renderCell: (row) => (
        <StatusTag
          value={get(row, 'status') ? 'active' : 'inactive'}
          variant={get(row, 'status') ? 'active' : 'inactive'}
        />
      )
    },
    { header: t('Дублировать'), key: 'Мерчант' },

    {
      header: t('Actions'),
      key: '',
      renderCell: (row) => (
        <div>
          <IconButton variant="text" size="sm" onClick={() => handleDelete(get(row, 'id'))}>
            <RiDeleteBin6Fill color="red" size={24} />
          </IconButton>
        </div>
      )
    }
  ];

  const { mutate, isLoading: deleteLoading } = useDeleteQuery({
    listKeyId: 'hall-events'
  });

  const handleDelete = (id) => {
    mutate(
      {
        url: '/admin/events/' + id
      },
      {
        onSuccess: ({ data }) => {},
        onError: () => {}
      }
    );
  };

  return (
    <>
      <ContentLayout
        title={t('Сеансы')}
        loader={isLoading || deleteLoading}
        rightAction={
          <Button link={Routes.SEANCE_CREATE} className="bg-accent-green">
            {t('create')}
          </Button>
        }>
        <Table hasEdit={true}editUrl={Routes.SEANCE_UPDATE} withOrder={true} content={content} list={elements} />
      </ContentLayout>
    </>
  );
}

export default SeanceList;

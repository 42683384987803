import React from 'react';

const ArrowUpIcon = (props) => {
  const { width = 14, height = '8', color = '#A1A1A1' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.9993 2.82756L11.9491 7.77734L13.3633 6.36312L6.9993 -0.000835141L0.635403 6.36312L2.0496 7.77734L6.9993 2.82756Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowUpIcon;

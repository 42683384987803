import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { navigationList } from '../../constants/routeConstants';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useNavigate, useResolvedPath } from 'react-router';
import { IoIosArrowDown } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { LogoutIcon } from '../../assets/icons/logout';
import Swal from 'sweetalert2';

function Sidebar() {
  const [, setCookie] = useCookies(['accessToken']);
  const [openChild,setOpenChild] = useState(true)
  const path = useResolvedPath().pathname;
  const { t } = useTranslation();
  const navigation = useNavigate();
  const active = (url) => {
    if (url === '/' && !path.split('/')[1]) {
      return true;
    }
    return url !== '/' ? path.includes(url) : false;
  };

  const logout = () => {
    Swal.fire({
      title: t('Чиқишга ишончингиз комилми?'),
      icon: 'warning',
      backdrop: 'rgba(0,0,0,0.9)',
      background: 'none',
      showCancelButton: true,
      confirmButtonColor: '#40c057',
      confirmButtonText: t('Ҳа албатта'),
      cancelButtonText: t('Ортга қайтиш'),
      customClass: {
        title: 'title-color',
        content: 'text-color',
        icon: 'icon-color'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setCookie('accessToken', null);
        localStorage.clear();
        sessionStorage.clear();
      }
    });
  };
  return (
    <div className="w-[200px] flex flex-col h-[100vh] bg-main-black py-[22px]">
      <div className="px-[28px]" onClick={() => navigation('/')}>
        <Logo width={150} />
      </div>
      <div className="flex-1 flex items-start flex-col mt-5 gap-2">
        {navigationList.map((item) => (
          <Link
            onClick={() => setOpenChild(prev => !prev)}
            key={item.id}
            to={item?.child ? item.child[0].path : item.path}
            className="w-full relative">
            <div
              className={cx(
                'flex items-center justify-between pl-8 pr-2 py-3 cursor-pointer hover:bg-black w-full transition',
                { ' text-main': active(item.path) }
              )}>
              <div className="flex items-center gap-4">
                <item.icon color={active(item.path) ? '#C61F1F' : '#A1A1A1'} />
                <p
                  className={cx('text-[14px] font-semibold', {
                    ' text-main': active(item.path)
                  })}>
                  {t(item.title)}
                </p>
              </div>

              {item?.child && (
                <span
                  style={{
                    transform: openChild && active(item.path) ? 'rotate(180deg)' : null,
                    transition: 'ease-in'
                  }}>
                  <IoIosArrowDown size={22} />
                </span>
              )}
            </div>
            {item?.child && active(item.path) && openChild && (
              <div onClick={() => setOpenChild(prev => !prev)} className="mt-1 ml-8 flex flex-col gap-1 ">
                {item.child.map((child) => (
                  <Link key={child.id} to={child.path}>
                    <div
                      className={cx(
                        'flex items-center gap-4 pl-5 pr-2 py-3 cursor-pointer hover:bg-black w-full transition rounded-tl-[12px] rounded-bl-[12px]',
                        { 'bg-black': active(child.path) }
                      )}>
                      <item.icon color={active(child.path) ? '#C61F1F' : '#A1A1A1'} />
                      <p
                        className={cx('text-[14px] font-semibold', {
                          'text-main': active(child.path)
                        })}>
                        {t(child.title)}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </Link>
        ))}
      </div>
      <div className="px-[28px] cursor-pointer flex gap-4" onClick={logout}>
        <LogoutIcon /> {t('Logout')}
      </div>
    </div>
  );
}

export default Sidebar;

import React from 'react';
import {toast} from "react-toastify";
function ToastSuccess (message, options) {
  return toast.success(
      message,
      {
        theme: "colored",
        ...options,
      },
  );
}


export default ToastSuccess;

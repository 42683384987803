import { Accordion, Box, Button as SButton, Flex, Menu, Text, Switch } from '@mantine/core';
import { Form, Formik, FieldArray } from 'formik';
import { get, isEqual } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Button from '../../components/button';
import ContentLayout from '../../components/content-layout';
import FileInput from '../../components/input/file-input';
import FormInput from '../../components/input/FormInput';
import SelectInput from '../../components/select/select-input';
import { Routes } from '../../constants/routes';
import { useGetAllQuery, usePostQuery } from '../../hooks/query';
import { STATUS_OPTION_STATUS } from '../events/create-container';
import * as Yup from 'yup';

const merchantValidationSchema = Yup.object().shape({
  nameUz: Yup.string().required('Required'),
  nameRu: Yup.string().required('Required'),
  nameEn: Yup.string().required('Required'),
  // addressUz: Yup.string().required('Required'),
  // addressRu: Yup.string().required('Required'),
  // addressEn: Yup.string().required('Required'),
  // descriptionUz: Yup.string().required('Required'),
  // descriptionRu: Yup.string().required('Required'),
  // descriptionEn: Yup.string().required('Required'),
  // tin: Yup.string().required('Required'),
  // organizationName: Yup.string().required('Required'),
  // director: Yup.string().required('Required'),
  // phoneDirector: Yup.string().required('Required'),
  // phone: Yup.string().required('Required'),
  // email: Yup.string().email('Invalid email').required('Required'),
  // contactOrganization: Yup.string().required('Required'),
  // locationLat: Yup.number().required('Required').typeError('Must be a valid number'),
  // locationLon: Yup.number().required('Required').typeError('Must be a valid number'),
  // workDays: Yup.array().of(
  //   Yup.object().shape({
  //     enabled: Yup.boolean(),
  //     time: Yup.string().when('enabled', {
  //       is: true,
  //       then: Yup.string().required('Required'),
  //       otherwise: Yup.string().notRequired(),
  //     }),
  //     endtime: Yup.string().when('enabled', {
  //       is: true,
  //       then: Yup.string().required('Required'),
  //       otherwise: Yup.string().notRequired(),
  //     }),
  //   })
  // ),
  // telegram: Yup.string().url('Invalid URL'),
  // instagram: Yup.string().url('Invalid URL'),
  // facebook: Yup.string().url('Invalid URL'),
  // categoriesId: Yup.string().required('Required'),
  // status: Yup.string().required('Required'),
});
const daysOfWeek = [
  { label: 'Понедельник', value: 'monday' },
  { label: 'Вторник', value: 'tuesday' },
  { label: 'Среда', value: 'wednesday' },
  { label: 'Четверг', value: 'thursday' },
  { label: 'Пятница', value: 'friday' },
  { label: 'Суббота', value: 'saturday' },
  { label: 'Воскресенье', value: 'sunday' }
];

function CreateMerchants() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { mutate, isLoading } = usePostQuery({
    listKeyId: 'product-list'
  });
  const [activeTab, setActiveTab] = useState(1);
  const { data: merchantList, isLoading: isLoadingMerchant } = useGetAllQuery({
    key: 'merchant',
    url: 'admin/merchant'
  });

  const addCategory = (data) => {
    const submittingData = {
      name: {
        uz: data?.nameUz,
        ru: data?.nameRu,
        en: data?.nameEn
      },
      address: {
        uz: data?.addressUz,
        ru: data?.addressRu,
        en: data?.addressEn
      },
      description: {
        uz: data?.descriptionUz,
        ru: data?.descriptionRu,
        en: data?.descriptionEn
      },
      image: {
        web: {
          mini: data?.webMini,
          large: data?.webLarge
        },
        mobile: {
          mini: data?.mobileMini,
          large: data?.mobileLarge
        }
      },
      merchantRequisite: {
        tin: data?.tin,
        organizationName: data?.organizationName,
        director: data?.director,
        phoneDirector: data?.phoneDirector,
        phone: data?.phone,
        email: data?.email,
        contactOrganization: data?.contactOrganization,
        location: {
          lat: data?.locationLat,
          lon: data?.locationLon
        },
        workInfo: {
          workDays: data?.workDays
        }
      },
      socialSet: {
        telegram: data?.telegram,
        instagram: data?.instagram,
        facebook: data?.facebook
      },
      categoriesId: data?.categoriesId,
      status: data?.status
    };

    mutate(
      {
        url: '/admin/merchants/',
        attributes: submittingData
      },
      {
        onSuccess: ({ data }) => {
          navigate(Routes.SEANCE);
        },
        onError: () => {}
      }
    );
  };

  return (
    <ContentLayout breadcrumb={true}>
      <Formik
        initialValues={{
          nameUz: '',
          nameRu: '',
          nameEn: '',
          addressUz: '',
          addressRu: '',
          addressEn: '',
          descriptionUz: '',
          descriptionRu: '',
          descriptionEn: '',
          tin: '',
          organizationName: '',
          director: '',
          phoneDirector: '',
          phone: '',
          email: '',
          contactOrganization: '',
          locationLat: '',
          locationLon: '',
          workDays: daysOfWeek.map((day) => ({
            weekDay: day.value,
            enabled: false,
            time: '', // for start time
            endtime: '' // for end time
          })),
          telegram: '',
          instagram: '',
          facebook: '',
          categoriesId: '',
          status: ''
        }}
        validationSchema={merchantValidationSchema} 
        onSubmit={addCategory}>
        {({ setFieldValue, values,errors,touched  }) => (
          <Form>
            {/* Name Inputs */}
            <p className="text-[20px] font-semibold mt-12 text-gray-2">
              {t('Названия поставщика')}
            </p>
            <div className="flex gap-6 flex-wrap my-6">
              <FormInput name="nameUz" label={t('Узбекский')} />
             
              <FormInput name="nameRu" label={t('Русский')} />
             
              <FormInput name="nameEn" label={t('Английский')} />
             
            </div>

            {/* Category Selection */}
            <p className="text-[20px] font-semibold mt-12 text-gray-2">
              {t('Принадлежность к категории')}
            </p>
            <div className="flex gap-6 flex-wrap my-6">
              <SelectInput name="categoriesId" label={t('Категория поставщика')} options={[]} />
             
              <SelectInput name="status" label={t('Статус')} options={STATUS_OPTION_STATUS(t)} />
             
            </div>

            {/* Mobile Images */}
            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Картинка для МП')}</p>
            <div className="flex gap-6 flex-wrap my-6">
              <FileInput
                onChange={(file) => setFieldValue('mobileMini', file)}
                label={t('Мини (760x340)')}
              />
              <FileInput
                onChange={(file) => setFieldValue('mobileLarge', file)}
                label={t('Крупная (800x640)')}
              />
            </div>

            {/* Web Images */}
            <p className="text-[20px] font-semibold mt-[120px]  text-gray-3">
              {t('Картинка для Веб')}
            </p>
            <div className="flex gap-6 flex-wrap my-6 mb-32">
              <FileInput
                onChange={(file) => setFieldValue('webMini', file)}
                label={t('Мини (760x400)')}
              />
              <FileInput
                onChange={(file) => setFieldValue('webLarge', file)}
                label={t('Крупная (1360x640)')}
              />
            </div>

            {/* Merchant Requisite Inputs */}
            <Accordion>
              <Accordion.Item label={t('Реквизиты поставщика')}>
                <div className="flex gap-6 flex-wrap my-6">
                  <FormInput name="tin" label={t('ИНН')} />
                  <FormInput name="organizationName" label={t('Название организации')} />
                  <FormInput name="director" label={t('Директор')} />
                  <FormInput name="phoneDirector" label={t('Телефон директора')} />
                  <FormInput name="phone" label={t('Телефон')} />
                  <FormInput name="email" label={t('Эл. адрес')} />
                  <FormInput name="contactOrganization" label={t('Контактная организация')} />
                  <FormInput name="locationLat" label={t('Широта')} />
                  <FormInput name="locationLon" label={t('Долгота')} />
                </div>

                <FieldArray
                  name="workDays"
                  render={(arrayHelpers) => (
                    <div>
                      <div className="flex gap-5">
                        <div className="w-[380px] text-gray-3 Aeonik Pro pl-2">
                          {t('Дни недели')}
                        </div>
                        <div className="w-[380px] text-gray-3 Aeonik Pro pl-2">
                          {t('Открывается с')}
                        </div>
                        <div className="w-[380px] text-gray-3 Aeonik Pro pl-2">
                          {t('Работает до')}
                        </div>
                      </div>
                      {values.workDays.map((day, index) => (
                        <div key={day.weekDay} className="flex items-center gap-6 my-3">
                          <div className="w-[380px] rounded-xl p-4 bg-main-black h-16 flex justify-between items-center">
                            <div>{t(daysOfWeek[index].label)}</div>
                            <Switch
                              color="red"
                              checked={day.enabled}
                              onChange={(event) =>
                                arrayHelpers.replace(index, {
                                  ...day,
                                  enabled: event.currentTarget.checked
                                })
                              }
                            />
                          </div>

                          <input
                            type="time"
                            className="w-[380px] rounded-xl p-4 bg-main-black h-16"
                            disabled={!day.enabled}
                            value={day.time || ''}
                            onChange={(event) => {
                              arrayHelpers.replace(index, {
                                ...day,
                                time: event.target.value
                              });
                            }}
                          />

                          {/* Closing time */}
                          <input
                            type="time"
                            className="w-[380px] rounded-xl p-4 bg-main-black h-16"
                            disabled={!day.enabled}
                            value={day.endtime || ''}
                            onChange={(event) => {
                              arrayHelpers.replace(index, {
                                ...day,
                                endtime: event.target.value
                              });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                />
              </Accordion.Item>
            </Accordion>

          
            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Адрес поставщика')}</p>

            <FormInput wFull name="addressUz" label={t('Узбекский')} />
            <FormInput wFull name="addressRu" label={t('Русский')} />
            <FormInput wFull name="addressEn" label={t('Английский')} />

           
            <p className="text-[20px] font-semibold mt-12 text-gray-2">
              {t('Описание поставщика')}
            </p>

            <FormInput wFull name="descriptionUz" label={t('Узбекский')} />
            <FormInput wFull name="descriptionRu" label={t('Русский')} />
            <FormInput wFull name="descriptionEn" label={t('Английский')} />

            {/* Social Set */}
            <p className="text-[20px] font-semibold mt-[60px]  text-gray-3">
              {t('Социальные сети')}
            </p>
            <div className="flex gap-6 flex-wrap my-6">
              <FormInput name="telegram" label={t('Телеграм')} />
              <FormInput name="instagram" label={t('Инстаграм')} />
              <FormInput name="facebook" label={t('Фейсбук')} />
            </div>


            <div className="flex items-center gap-6 mt-32">
              <Button type="submit" loading={isLoading} className="bg-accent-green">
                {t('create')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ContentLayout>
  );
}

export default CreateMerchants;

import { Accordion, Box, Button as SButton, Flex, Menu, Text } from '@mantine/core';
import { Form, Formik } from 'formik';
import { get, isEqual } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Button from '../../components/button';
import ContentLayout from '../../components/content-layout';

import FormInput from '../../components/input/FormInput';
import SelectInput from '../../components/select/select-input';
import { Routes } from '../../constants/routes';
import { useGetAllQuery, usePostQuery } from '../../hooks/query';
import { STATUS_OPTION_STATUS } from '../events/create-container';
import { LuPlus } from 'react-icons/lu';
import HallWrapper from '../hall/halls/modules/element/HallWrapper';

export const STATUS_OPTIONTYPE = (t) => {
  return [
    { value: 'INACTIVE', label: t('0 - scene') },
    { value: 'SEAT', label: t('1 - seat') },
    { value: 'DUAL', label: t('2 - Duo') },
    { value: 'MULTIPLE', label: t('Multiple') },
    { value: 'TEXT', label: t('Text') }
  ];
};

function CreateSeances() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { mutate, isLoading } = usePostQuery({
    listKeyId: 'product-list'
  });
  const [activeTab, setActiveTab] = useState(1);
  const { data: merchantList, isLoading: isLoadingMerchant } = useGetAllQuery({
    key: 'merchant',
    url: 'admin/merchant'
  });
  const addCategory = (data) => {
    const submittingData = {
      figure: get(data, 'figure.value'),
      isScalable: get(data, 'isScalable'),
      type: get(data, 'type'),
      name: {
        uz: data?.nameUz,
        ru: data?.nameRu,
        en: data?.nameEn
      },
      width: data?.elementWidth,
      height: data?.elementHeight
    };

    mutate(
      {
        url: '/admin/sessions/',
        attributes: submittingData
      },
      {
        onSuccess: ({ data }) => {
          navigate(Routes.SEANCE);
        },
        onError: () => {}
      }
    );
  };

  return (
    <ContentLayout breadcrumb={true}>
      <Formik initialValues={{ figure: true, elementSize: true, type: '' }} onSubmit={addCategory}>
        {({ setFieldValue, values, errors, setFieldTouched }) => (
          <Form>
            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Информация сеанса')}</p>
            <div className="flex gap-6 flex-wrap my-6">
              <SelectInput
                onChange={(ev) => {
                  setFieldTouched('addiction_merchant', true);
                  setFieldValue('addiction_merchant', ev?.value);
                }}
                name="addiction_merchant"
                options={get(merchantList, 'data', []).map((merchant) => {
                  return {
                    label: get(merchant, 'name.uz'),
                    value: get(merchant, 'id')
                  };
                })}
                label={t('Выберите мерчант')}
              />
              {errors.addiction_merchant && !touched.addiction_merchant && (
                <p className="text-[14px] text-main">{t('addiction_merchant is required')}</p>
              )}
              <SelectInput
                onChange={(ev) => {
                  setFieldTouched('addiction_merchant', true);
                  setFieldValue('addiction_merchant', ev?.value);
                }}
                name="addiction_merchant"
                options={get(merchantList, 'data', []).map((merchant) => {
                  return {
                    label: get(merchant, 'name.uz'),
                    value: get(merchant, 'id')
                  };
                })}
                label={t('Выберите зал мерчанта')}
              />
              {errors.addiction_merchant && !touched.addiction_merchant && (
                <p className="text-[14px] text-main">{t('addiction_merchant is required')}</p>
              )}
              <SelectInput
                onChange={(ev) => {
                  setFieldTouched('addiction_merchant', true);
                  setFieldValue('addiction_merchant', ev?.value);
                }}
                name="addiction_merchant"
                options={get(merchantList, 'data', []).map((merchant) => {
                  return {
                    label: get(merchant, 'name.uz'),
                    value: get(merchant, 'id')
                  };
                })}
                label={t('Условия сотрудничества')}
              />
              {errors.addiction_merchant && !touched.addiction_merchant && (
                <p className="text-[14px] text-main">{t('addiction_merchant is required')}</p>
              )}
            </div>

            <SelectInput
              className={'my-5'}
              label={t('Status')}
              onChange={(ev) => setFieldValue('status', ev?.value)}
              options={STATUS_OPTION_STATUS(t)}
            />

            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Дата и время')}</p>

            <FormInput type="date" name="date" label={t('Даты показа')} />
            <div className="flex items-center gap-6 my-6">
              <FormInput type="text" name="date-h" label={t('Начало показа')} />
              <FormInput type="text" name="date-m" label={t('Начало показа')} />
            </div>

            <hr className="bg-[#1D1D1D90]" />

            <p className="text-[20px] font-semibold my-6 text-gray-3">{t('Шаблоны')}</p>
            <Flex gap={'16px'}>
              <SButton.Group maw={'380px'} bg={'#111111'}>
                <SButton
                  onClick={() => setActiveTab(1)}
                  fullWidth
                  styles={{
                    root: {
                      border: 'none',
                      borderRadius: isEqual(activeTab, 1) ? '12px' : '0'
                    },
                    label: {
                      color: isEqual(activeTab, 1) ? '#C61F1F' : '#fff'
                    }
                  }}
                  bg={isEqual(activeTab, 1) ? '#1D1D1D' : 'transparent'}
                  variant="default">
                  {t('Шаблоны')}
                </SButton>
                <SButton
                  onClick={() => setActiveTab(2)}
                  fullWidth
                  styles={{
                    root: {
                      border: 'none',
                      borderRadius: isEqual(activeTab, 2) ? '12px' : '0'
                    },
                    label: {
                      color: isEqual(activeTab, 2) ? '#C61F1F' : '#fff'
                    }
                  }}
                  bg={isEqual(activeTab, 2) ? '#1D1D1D' : 'transparent'}
                  variant="default">
                  {t('Шаблон цен 2 – Стандартный')}
                </SButton>
              </SButton.Group>
              <SButton variant="filled" bg={'#088157'}>
                <LuPlus /> {t('Сохранить как шаблон')}
              </SButton>
            </Flex>

            <p className="text-[20px] font-semibold my-6 text-gray-3">{t('Категории')}</p>

            <Flex wrap={'wrap'} gap={'lg'}>
              <Box maw={'250px'} className="cursor-pointer" mt={'lg'} bg={'#1D1D1D'} p={'lg'}>
                <Flex gap={'lg'}>
                  <Box w={'48px'} h={'48px'} bg={'#2622FE'}></Box>
                  <Box>
                    <Text>{t('Категория 0')}</Text>
                    <Text>{t('250 000 сум')}</Text>
                  </Box>
                </Flex>
              </Box>
              <Box maw={'250px'} className="cursor-pointer" mt={'lg'} bg={'#1D1D1D'} p={'lg'}>
                <Flex gap={'lg'}>
                  <Box w={'48px'} h={'48px'} bg={'#C61F1F'}></Box>
                  <Box>
                    <Text>{t('Категория 1')}</Text>
                    <Text>{t('200 000 сум')}</Text>
                  </Box>
                </Flex>
              </Box>
              <Box maw={'250px'} className="cursor-pointer" mt={'lg'} bg={'#1D1D1D'} p={'lg'}>
                <Flex gap={'lg'}>
                  <Box w={'48px'} h={'48px'} bg={'#E0A100'}></Box>
                  <Box>
                    <Text>{t('Категория 2')}</Text>
                    <Text>{t('150 000 сум')}</Text>
                  </Box>
                </Flex>
              </Box>
              <Box maw={'250px'} className="cursor-pointer" mt={'lg'} bg={'#1D1D1D'} p={'lg'}>
                <Flex gap={'lg'}>
                  <Box w={'48px'} h={'48px'} bg={'#088157'}></Box>
                  <Box>
                    <Text>{t('Категория 3')}</Text>
                    <Text>{t('100 000 сум')}</Text>
                  </Box>
                </Flex>
              </Box>
              <Box maw={'250px'} className="cursor-pointer" mt={'lg'} bg={'#1D1D1D'} p={'lg'}>
                <Flex gap={'lg'}>
                  <Box w={'48px'} h={'48px'} bg={'#009FD0'}></Box>
                  <Box>
                    <Text>{t('Категория 4')}</Text>
                    <Text>{t('80 000 сум')}</Text>
                  </Box>
                </Flex>
              </Box>
              <Box maw={'250px'} className="cursor-pointer" mt={'lg'} bg={'#1D1D1D'} p={'lg'}>
                <Flex gap={'lg'}>
                  <Box w={'48px'} h={'48px'} bg={'#0E6200'}></Box>
                  <Box>
                    <Text>{t('Категория 5')}</Text>
                    <Text>{t('70 000 сум')}</Text>
                  </Box>
                </Flex>
              </Box>
              <Box maw={'250px'} className="cursor-pointer" mt={'lg'} bg={'#1D1D1D'} p={'lg'}>
                <Flex gap={'lg'}>
                  <Box w={'48px'} h={'48px'} bg={'#0055A3'}></Box>
                  <Box>
                    <Text>{t('Категория 6')}</Text>
                    <Text>{t('50 000 сум')}</Text>
                  </Box>
                </Flex>
              </Box>

              <Box maw={'250px'} className="cursor-pointer" mt={'lg'} bg={'#1D1D1D'} p={'lg'}>
                <Flex gap={'lg'}>
                  <Box
                    display={'flex'}
                    className="rounded-[50%] justify-center items-center"
                    w={'48px'}
                    h={'48px'}
                    bg={'rgba(8, 129, 87,0.2)'}>
                    <LuPlus color="rgba(8, 129, 87,1)" />
                  </Box>
                  <Box>
                    <Text>{t('Добавить')}</Text>
                    <Text>{t('Категорию')}</Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>

            <hr className="bg-[#1D1D1D90]" />
            {/* <HallWrapper /> */}

            <hr className="bg-[#1D1D1D90]" />
            <div className="flex items-center gap-6 mt-10">
              <Button className="bg-main-black">Отменить</Button>
              <Button type="submit" className="bg-accent-green">
                {t('Добавить')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ContentLayout>
  );
}

export default CreateSeances;

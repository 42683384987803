import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { t } from 'i18next';
import Button from '../../../../components/button';
import ContentLayout from '../../../../components/content-layout';
import Loader from '../../../../components/loader';
import { Routes } from '../../../../constants/routes';
import { usePostQuery } from '../../../../hooks/query';
import BaseForm from './components/baseForm';
import BreadCump from './components/breadcump';
import HallWrapper from './element/HallWrapper';
import HallSectionWrapper from './section/wrapper';
import { get } from 'lodash';
import { clearHall, setHall } from '../../../../store/slices/settingsSlice';
import ToastSuccess from '../../../../components/notification/SuccessNotification';

function CreateHallScreen(props) {
  const hall = useSelector((state) => state.settings.hall);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate, isLoading } = usePostQuery({
    listKeyId: 'product-list'
  });


  const createHall = () => {
    mutate(
      {
        url: '/admin/halls/info',
        attributes: hall
      },
      {
        onSuccess: ({ data }) => {
          ToastSuccess('Hall successfully created');
          dispatch(clearHall());
          navigate(Routes.HALLS);
        },
        onError: (er) => {}
      }
    );
  };
  const [activeContent, setActiveContent] = useState(+searchParams.get('tab'));

  if (isLoading) {
    return <Loader />;
  }
  return (
    <ContentLayout breadcrumb={false}>
      <BreadCump
        activeContent={activeContent}
        setActiveContent={setActiveContent}
        setSearchParams={setSearchParams}
      />
      {activeContent === 3 ? (
        <>
          <HallWrapper
            setSearchParams={setSearchParams}
            activeContent={activeContent}
            setActiveContent={setActiveContent}
          />
        </>
      ) : activeContent === 2 ? (
        <HallSectionWrapper
          setSearchParams={setSearchParams}
          activeContent={activeContent}
          setActiveContent={setActiveContent}
        />
      ) : (
        <>
          <BaseForm activeContent={activeContent} setActiveContent={setActiveContent} />
          {get(hall, 'hall.sections', []).length > 0 && (
            <Button onClick={createHall} className="bg-accent-green  w-[320px] mt-3">
              {t('Create hall with sections')}
            </Button>
          )}
        </>
      )}
    </ContentLayout>
  );
}

export default CreateHallScreen;

import { IconButton } from '@material-tailwind/react';
import { get, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import Button from '../../components/button';
import ContentLayout from '../../components/content-layout';
import ToastSuccess from '../../components/notification/SuccessNotification';
import Table from '../../components/table';
import { Routes } from '../../constants/routes';
import { useDeleteQuery, useGetAllQuery } from '../../hooks/query';
import useGetImages from '../../hooks/useGetImages/useGetImages';
import elementCreate from '../../utils/elementCreate';
import StatusTag from '../../components/status/StatusTag';
import { Button as SButton } from '@mantine/core';
import moment from 'moment';
import { timeFormat } from '../../utils';

function SmsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllQuery({
    key: 'sms-template',
    url: 'template/sms'
  });

  const elements = get(data, 'data', []);

  const content = [
    {
      header: t('SMS–ID'),
      key: 'id',
      width: 200,
      renderCell: (row) => get(row, 'id')
    },

    { header: t('Шаблон'), key: 'title[uz]', renderCell: (row) => row.template?.uz },
    { header: t('Причина использования'), key: 'screenLanguage' },

    { header: t('Кому отправлено'), key: 'placeholderNames' },
    {
      header: t('Когда отправлено'),
      key: 'createdDate',

      renderCell: (row) => moment(row?.createdDate).format(timeFormat)
    },

    {
      header: t('Status'),

      key: 'status',
      renderCell: (row) => (
        <StatusTag
          value={get(row, 'status') ? 'active' : 'inactive'}
          variant={get(row, 'status') ? 'active' : 'inactive'}
        />
      )
    },
    { header: t('Дублировать'), key: 'Мерчант' },

    {
      header: t('Actions'),
      key: '',
      renderCell: (row) => (
        <div>
          <IconButton variant="text" size="sm" onClick={() => handleDelete(get(row, 'id'))}>
            <RiDeleteBin6Fill color="red" size={24} />
          </IconButton>
        </div>
      )
    }
  ];

  const { mutate, isLoading: deleteLoading } = useDeleteQuery({
    listKeyId: 'hall-events'
  });

  const handleDelete = (id) => {
    mutate(
      {
        url: '/template/sms/' + id
      },
      {
        onSuccess: ({ data }) => {},
        onError: () => {}
      }
    );
  };

  return (
    <>
      <ContentLayout
        title={t('Sms template')}
        loader={isLoading || deleteLoading}
        rightAction={
          <Button link={Routes.SMS_TEMPLATE_CREATE} className="bg-accent-green">
            {t('create')}
          </Button>
        }>
        <Table
          hasEdit={true}
          editUrl={Routes.SMS_TEMPLATE_UPDATE}
          withOrder={true}
          content={content}
          list={elements}
        />
      </ContentLayout>
    </>
  );
}

export default SmsList;

import { useEffect, useState } from 'react';
import ToastError from '../../components/notification/ErrorNotification';
import { httpClient } from '../../services';

const useGetImage = (url) => {
  const [base64Image, setBase64Image] = useState(null);

  useEffect(() => {
    if (url) {
      fetchImage(url);
    }
  }, [url]);

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const fetchImage = async (url) => {
    try {
      const response = await httpClient.get(url, { responseType: 'arraybuffer' });
      const base64 = arrayBufferToBase64(response.data);
      const dataUrl = `data:image/png;base64,${base64}`;
      setBase64Image(dataUrl);
    } catch (error) {
      ToastError(error);
    }
  };

  return base64Image;
};

export default useGetImage;

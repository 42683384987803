import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { store } from '../src/store';
import { BASE_URL } from './services';

const UZ = store.getState().settings.languages?.uz;
const RU = store.getState().settings.languages?.ru;
const EN = store.getState().settings.languages?.en;


i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: EN },
      uz: { translation: UZ },
      ru: { translation: RU }
    },
    lng: 'en',
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'location'],
      caches: ['localStorage']
    },

    supportedLngs: ['en', 'uz', 'ru'],
    missingKeyHandler: function (lng, ns, key, fallbackValue) {
   
    },
    saveMissing: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false,
      wait: true
    },
    // backend: {
    //   loadPath: `${BASE_URL}/site/translations/{{lng}}/react`,
    //   addPath: `${BASE_URL}/site/translations`
    // }
  });
export default i18n;

import React from "react";

function SupplierIcon({size= 24,color='#A1A1A1',className}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            className={className}
            fill="none"
            viewBox={`0 0 ${size} ${size}`}
        >
            <path
                fill={color}
                d="M12.4 13.533l5 6.667a.5.5 0 01-.4.8H7a.5.5 0 01-.4-.8l5-6.667a.5.5 0 01.8 0zM18 19v-2h2V5H4v12h2v2H2.992A.994.994 0 012 18V4c0-.552.455-1 .992-1h18.016c.548 0 .992.445.992 1v14c0 .552-.455 1-.992 1H18z"
            ></path>
        </svg>
    );
}

export default SupplierIcon;

import React from "react";

function SettingsIcon({size= 24,color='#A1A1A1',className}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            className={className}
            fill="none"
            viewBox={`0 0 ${size} ${size}`}
        >
            <path
                fill={color}
                d="M3.34 17a10.017 10.017 0 01-.979-2.326 3 3 0 00.003-5.347 9.99 9.99 0 012.5-4.337 3 3 0 004.632-2.674 9.99 9.99 0 015.007.003 3 3 0 004.632 2.671c.578.59 1.093 1.262 1.525 2.01.432.749.757 1.53.978 2.326a3 3 0 00-.002 5.347 9.99 9.99 0 01-2.501 4.337 3 3 0 00-4.632 2.674 9.99 9.99 0 01-5.007-.002 3 3 0 00-4.631-2.672A10.018 10.018 0 013.339 17zm5.66.196a4.992 4.992 0 012.25 2.77c.499.047 1 .048 1.499.002a4.993 4.993 0 012.25-2.772 4.993 4.993 0 013.526-.564c.29-.408.54-.843.748-1.298A4.993 4.993 0 0118 12c0-1.26.47-2.437 1.273-3.334a8.152 8.152 0 00-.75-1.298A4.993 4.993 0 0115 6.804a4.993 4.993 0 01-2.25-2.77c-.5-.047-1-.048-1.5-.001A4.993 4.993 0 019 6.804a4.993 4.993 0 01-3.526.564c-.29.408-.54.843-.747 1.298A4.993 4.993 0 016 12c0 1.26-.471 2.437-1.273 3.334a8.16 8.16 0 00.75 1.298A4.993 4.993 0 019 17.196zM12 15a3 3 0 110-6 3 3 0 010 6zm0-2a1 1 0 100-2 1 1 0 000 2z"
            ></path>
        </svg>
    );
}

export default SettingsIcon;

export const Routes = {
  HOME: '/',

  USER: '/user',
  USER_ITEM: '/user/info',

  CATEGORY: '/category',
  ADD_CATEGORY: '/category/add-category',
  EDIT_CATEGORY: '/category/:id',

  MERCHANT: '/merchant',
  MERCHANT_CREATE: '/merchant/create',
  MERCHANT_UPDATE: '/merchant/update',
  SEANCE: '/seance',
  SEANCE_UPDATE: '/seance/update',
  SEANCE_CREATE: '/seance/create',

  HALL: '/hall',
  HALLS: '/hall/halls',
  ELEMENTS: '/hall/elements',
  CREATE_ELEMENTS: '/hall/elements/create',
  UPDATE_ELEMENTS: '/hall/elements/update',
  CREATE_HALL: '/halls/create',
  HALL_EDITOR: '/halls/create/editor',

  EVENTS: '/events',
  EVENTS_CREATE: '/events/create',
  EVENTS_UPDATE: '/events/update',
  STORY: '/story',
  CREATE_STORY: '/story/create',
  STAFF: '/staff',
  CREATE_STAFF: '/staff/create-staff',
  SETTINGS: '/settings',
  SETTINGS_FAQ: '/settings/faq',
  CREATE_SETTINGS_FAQ: '/settings/faq/add',
  SETTINGS_DOCUMENTS: '/settings/documents',
  ADD_DOCUMENTS: '/settings/documents/create',
  UPDATE_DOCUMENTS: '/settings/documents',
  APP_LANGUAGES: '/settings/app-languages',
  LANGUAGES: '/settings/user-languages',
  
  SMS_TEMPLATE: "/sms-templates",
  SMS_TEMPLATE_CREATE: "/sms-templates/create",
  SMS_TEMPLATE_UPDATE: "/sms-templates/update" 

};

import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { httpClient } from '../../services';

const useGetAllQuery = (props) => {
  const { t } = useTranslation();
  const {
    key = 'get-all',
    url = '/',
    params = {},
    showSuccessMsg = false,
    hideErrorMsg = false,
    enabled = true,
    options = {
      onSuccess: () => {},
      onError: (data) => {
        if (!hideErrorMsg) {
          const message = get(data, 'response.data.message');
         
        }
      }
    }
  } = props;
  const { isLoading, isError, data, error, isFetching } = useQuery(
    [key, params],
    () => httpClient.get(url, params),
    {
      ...options,
      enabled
    }
  );
  return {
    isLoading,
    isError,
    data,
    error,
    isFetching
  };
};

export default useGetAllQuery;

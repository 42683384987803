import { forEach, get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { httpClient } from '../../services';
import ToastSuccess from '../../components/notification/SuccessNotification';
const deleteRequest = (url, attributes) => httpClient.delete(url, { data: attributes });
const useDeleteQuery = ({ hideSuccessToast = false, hideErrorToast = false, listKeyId = null }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate, isLoading, isError, error, isFetching } = useMutation(
    ({ url, attributes }) => deleteRequest(url, attributes),
    {
      onSuccess: (data) => {
        const message = get(data, 'data.message');
        ToastSuccess(t('Element successfully deleted'));
        if (!hideSuccessToast) {
          if (listKeyId) {
            if (isArray(listKeyId)) {
              forEach(listKeyId, (val) => {
                queryClient.invalidateQueries(val);
              });
            } else {
              queryClient.invalidateQueries(listKeyId);
            }
          }
        }
      },
      onError: (data) => {
        const message = get(data, 'response.data.message');
        if (!hideErrorToast) {
        }
      }
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    error,
    isFetching
  };
};
export default useDeleteQuery;

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { updateMatrixElement } from '../../../../../utils/updateMatrixElem';
import { MapInteractionCSS } from 'react-map-interaction';
import { get, isEmpty, isEqual } from 'lodash';
import { t } from 'i18next';
import ToastError from '../../../../../components/notification/ErrorNotification';
import elementCreate from '../../../../../utils/elementCreate';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import useGetImages from '../../../../../hooks/useGetImages/useGetImages';

const resizeX = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 9V6L20 10L16 14V11H11V16H14L10 20L6 16H9V11H4V14L0 10L4 6V9H9V4H6L10 0L14 4H11V9H16Z"
      fill="#C61F1F"
    />
  </svg>
);
const resizeY = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 3H13.5L16.5429 6.04289L13.2929 9.29289L14.7071 10.7071L17.9571 7.45711L21 10.5V3ZM3 21H10.5L7.45711 17.9571L10.7071 14.7071L9.29289 13.2929L6.04289 16.5429L3 13.5V21Z"
      fill="#C61F1F"
    />
  </svg>
);

const HallGrid = ({
  items,
  setItems,
  setCurrentBox,
  currentBox,
  activeElement,
  coordinates,
  elements,
  setSelectedElements
}) => {
  const [zoomValue, setZoomValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 }
  });

  const { base64Images } = useGetImages({ data: elements });
  const [openModalItem, setOpenModalItem] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [resizeDirection, setResizeDirection] = useState(null);

  useEffect(() => {
    handleResetZoom();
  }, [coordinates]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentBox]);

  const handleKeyDown = (ev) => {
    //Left
    if (ev.keyCode == 37) {
      setCurrentBox((prev) => {
        if (prev.y - 1 >= 0) {
          return {
            x: prev.x,
            y: prev.y - 1
          };
        } else return prev;
      });
      return;
    }
    //up
    if (ev.keyCode == 38) {
      setCurrentBox((prev) => {
        if (prev.x >= 1) {
          return {
            y: prev.y,
            x: prev.x - 1
          };
        } else return prev;
      });
      return;
    }
    //right
    if (ev.keyCode == 39) {
      setCurrentBox((prev) => {
        if (items[0]?.length - 1 >= currentBox?.y + 1) {
          return {
            x: prev.x,
            y: prev.y + 1
          };
        } else return prev;
      });
      return;
    }
    //down
    if (ev.keyCode == 40) {
      setCurrentBox((prev) => {
        if (items.length - 2 >= prev.x) {
          return {
            y: prev.y,
            x: prev.x + 1
          };
        } else return prev;
      });
      return;
    }
    // enter
    if (ev.keyCode == 13) {
      if (currentBox?.x >= 0 && currentBox?.y >= 0) {
        updateMatrixElement(currentBox.x, currentBox.y, activeElement, setItems);
      }

      setCurrentBox((prev) => {
        if (items[prev.x].length - 2 >= prev.y + 1)
          return {
            x: prev.x,
            y: prev.y + 1
          };
      });
    }
    if (ev.keyCode == 46) {
      updateMatrixElement(currentBox?.x, currentBox?.y, null, setItems);
    }
  };

  const isAreaEmpty = (x, y) => {
    if (activeElement?.width && activeElement?.height) {
      if (x + activeElement.height > items.length || y + activeElement.width > items[0].length) {
        ToastError(t('Not enough space to draw the element'), '', {
          toastId: 'error'
        });
        return false;
      }
      for (let i = 0; i < activeElement.height; i++) {
        for (let j = 0; j < activeElement.width; j++) {
          if (items[x + i] && items[x + i][y + j]) {
            ToastError(t('Area is not empty'), '', {
              toastId: 'error'
            });

            return false; // Found a non-empty cell, so the area is not empty
          }
        }
      }
    } else {
      return !items[x][y]; // Check if the single cell is empty
    }
    return true; // All cells in the area are empty
  };

  const handleDoubleClick = (x, y) => {
    if (isEmpty(activeElement)) {
      ToastError(t('Please select element'), '', {
        toastId: 'error'
      });
      return false;
    }
    if (isAreaEmpty(x, y)) {
      if (activeElement?.width && activeElement?.height) {
        setSelectedElements((prev) => [
          ...prev,
          {
            ...activeElement,
            x,
            y
          }
        ]);

        for (let i = 0; i < activeElement.height; i++) {
          for (let j = 0; j < activeElement.width; j++) {
            updateMatrixElement(
              x + i,
              y + j,
              {
                ...activeElement,
                show: i == 0 && j == 0
              },
              setItems
            );
          }
        }
      } else {
        updateMatrixElement(x, y, activeElement, setItems);
      }
    }
  };

  const handleZoomChange = (value) => {
    if (
      value.scale > 0.5 &&
      value.scale < 3 &&
      value.translation.x > -300 &&
      value.translation.x < 300 &&
      value.translation.y > -300 &&
      value.translation.y < 300
    ) {
      setZoomValue(value);
    }
  };

  const handleDelete = (item, x, y) => {
    for (let i = 0; i < item.height; i++) {
      for (let j = 0; j < item.width; j++) {
        updateMatrixElement(x + i, y + j, null, setItems);
      }
    }
  };

  const handleResetZoom = () => setZoomValue({ scale: 1, translation: { x: 0, y: 0 } });

  const handleResizeStart = (direction) => {
    setIsResizing(true);
    setResizeDirection(direction);

    document.addEventListener('mousemove', handleResizing);
    document.addEventListener('mouseup', handleResizeEnd);
  };

  const handleResizing = (event) => {
    if (!isResizing || !currentBox) return;
    const { x, y } = currentBox;
    let newItems = [...items];
    let element = newItems[x][y];

    if (resizeDirection === 'x') {

      // let newWidth = Math.floor((event.clientX - event.target.getBoundingClientRect().left) / 44);
      // element = { ...element, width: newWidth };
    } else if (resizeDirection === 'y') {
      let newHeight = Math.floor((event.clientY - event.target.getBoundingClientRect().top) / 44);
      element = { ...element, height: newHeight };
    }

    newItems[x][y] = element;
    setItems(newItems);
  };

  const handleResizeEnd = () => {
    setIsResizing(false);
    setResizeDirection(null);
    document.removeEventListener('mousemove', handleResizing);
    document.removeEventListener('mouseup', handleResizeEnd);
  };
  return (
    <div className=" pt-5 relative flex flex-col items-center justify-start flex-1 overflow-auto">
      <MapInteractionCSS value={zoomValue} onChange={handleZoomChange}>
        {items.map((row, x) => (
          <div key={x} className="flex">
            {row.map((item, y) => (
              <div
                key={y}
                onClick={() => {
                  if (x == currentBox?.x && y == currentBox?.y) {
                    setOpenModalItem({ x, y });
                  } else {
                    setOpenModalItem({});
                  }
                  setCurrentBox({ x, y });
                }}
                onDoubleClick={() => handleDoubleClick(x, y)}
                // ref={drop}
                className={cx(
                  'grid-element',
                  {
                    'cursor-animation': x == currentBox?.x && y == currentBox?.y
                  },
                  'h-[44px] w-[44px]'
                )}>
                {get(item, 'show') ? (
                  <div
                    style={{
                      width: 40 * get(item, 'width'),
                      height: 40 * get(item, 'height'),
                      backgroundImage: `url(${base64Images[get(item, 'figure', '')]})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }}
                    className={cx(
                      'grid-element resizable',
                      {
                        'cursor-animation': x == currentBox?.x && y == currentBox?.y
                      },
                      'h-[44px] w-[44px]',
                      elementCreate(get(item, 'figure')) +
                        `  top-0 left-0  absolute ${get(item, 'show') ? 'flex' : 'hidden'}`
                    )}>
                    {openModalItem.x === x && openModalItem.y === y && (
                      <div className="absolute z-50 rounded-[12px] min-w-[200px] card bottom-[110%] bg-[#111111]">
                        <h4 className="text-white  p-[16px]">
                          Сиденье {x}x{y}
                        </h4>
                        <div></div>

                        <div
                          className="w-full px-[16px]  gap-1 py-3 btn btn-danger flex  items-center border-t-2 border-[#1D1D1D]"
                          size="sm"
                          onClick={() => handleDelete(item, x, y)}>
                          <RiDeleteBin6Fill color="#C61F1F" size={24} />
                          <span className="text-main">{t('Delete')}</span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={cx(
                      'grid-element resizable',
                      {
                        'cursor-animation': x == currentBox?.x && y == currentBox?.y
                      },
                      'h-[44px] w-[44px]',
                      elementCreate(get(item, 'figure')) +
                        `  top-0 left-0  absolute ${get(item, 'show') ? 'flex' : 'hidden'}`
                    )}>
                    {openModalItem.x === x && openModalItem.y === y && (
                      <div className="absolute z-50 rounded-[12px] min-w-[200px] card bottom-[110%] bg-[#111111]">
                        <h4 className="text-white  p-[16px]">
                          Сиденье {x}x{y}
                        </h4>
                        <div></div>

                        <div
                          className="w-full px-[16px]  gap-1 py-3 btn btn-danger flex  items-center border-t-2 border-[#1D1D1D]"
                          size="sm"
                          onClick={() => handleDelete(item, x, y)}>
                          <RiDeleteBin6Fill color="#C61F1F" size={24} />
                          <span className="text-main">{t('Delete')}</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </MapInteractionCSS>
      <div className="zoom-controls flex gap-2 fixed bottom-4 right-4    p-4 bg-gray-800 rounded-lg shadow-lg">
        <button
          onClick={() => handleZoomChange({ ...zoomValue, scale: zoomValue.scale * 1.1 })}
          className="text-main bg-[#1D1D1D] rounded-xl hover:bg-blue-600 px-5 py-2 text-[24px]  focus:outline-none focus:ring-2 focus:ring-blue-400">
          +
        </button>
        <button
          onClick={() => handleZoomChange({ ...zoomValue, scale: zoomValue.scale * 0.9 })}
          className="text-main bg-[#1D1D1D] hover:bg-blue-600 px-5 py-2 text-[24px] rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-400">
          -
        </button>
        <button
          onClick={handleResetZoom}
          className="text-main bg-[#1D1D1D] hover:bg-red-600 px-5 py-2 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-400">
          Reset
        </button>
      </div>
    </div>
  );
};

export default HallGrid;
{
  /* Resize icons */
}
{
  /* {x == currentBox?.x && y == currentBox?.y && get(item, 'isScalable') ? (
                    <>
                      <span
                        className="absolute z-50  bottom-full left-full"
                        onMouseDown={() => handleResizeStart('x')}>
                        {resizeX}
                      </span>
                      <span
                        className="absolute z-50  top-full left-full"
                        onMouseDown={() => handleResizeStart('y')}>
                        {resizeY}
                      </span>
                    </>
                  ) : (
                    ''
                  )} */
}

export const CATEGORY_API = 'admin/category/event';

export const SETTINGS_FAQ_API = 'admin/faq';

export const SETTINGS_DOCUMENTS_API = 'admin/offer';

export const STORIES_API = 'admin/stories';
export const STAFFS_API = 'admins';

export const APP_LANGUAGES_API = 'admin/setting/app-lang';

import { Accordion, Box, Button as SButton, Flex, Menu, Text } from '@mantine/core';
import { FieldArray, Form, Formik } from 'formik';
import { get, isEqual } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Button from '../../components/button';
import ContentLayout from '../../components/content-layout';
import FileInput from '../../components/input/file-input';
import FormInput from '../../components/input/FormInput';
import ToastSuccess from '../../components/notification/SuccessNotification';
import SelectInput from '../../components/select/select-input';
import { Routes } from '../../constants/routes';
import { usePostQuery } from '../../hooks/query';
import { MovieIcon } from '../../assets/icons/movie-icon';

export const STATUS_OPTION_STATUS = (t) => {
  return [
    { value: "active", label: <Text c="#088157">{t('Active')}</Text> },
    { value: "deactive", label: <Text c="#C61F1F">{t('Deactive')}</Text> }
  ];
};
const STATUS_OPTION_LANGUAGE = (t) => {
  return [
    { value: 'uz', label: <Text>{t('Uz')}</Text> },
    { value: 'ru', label: <Text>{t('Ru')}</Text> },
    { value: 'en', label: <Text>{t('En')}</Text> }
  ];
};
const STATUS_OPTION_AGE = (t) => {
  return [
    { value: 6, label: <Text>6</Text> },
    { value: 12, label: <Text>12</Text> },
    { value: 16, label: <Text>16</Text> },
    { value: 18, label: <Text>18</Text> }
  ];
};

function CreateEvent() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { mutate, isLoading } = usePostQuery({
    listKeyId: 'product-list'
  });
  const [activeTab, setActiveTab] = useState(1);
  const [activeTab2, setActiveTab2] = useState(1);

 
  const addCategory = (data) => {
    const submittingData = {
      status: get(data, 'status'),
      title: {
        uz: data?.nameUz,
        ru: data?.nameRu,
        en: data?.nameEn
      },
      image: {
        mini: get(data, 'mini'),
        mobileLarge: get(data, 'mobileLarge'),
        webLarge: get(data, 'webLarge')
      },

      description: {
        uz: data?.descUz,
        ru: data?.descRu,
        en: data?.descEn
      },
      details: [
        {
          uz: data?.detailsUz,
          ru: data?.detailsRu,
          en: data?.detailsEn
        }
      ],

      durationMinutes: get(data, 'durationMinutes'),
      screenLanguage: get(data, 'screenLanguage'),
      viewerAge: get(data, 'viewerAge'),
      trailer: get(data, 'trailer'),
      // subDetails: get(data, 'subDetails'),
    };

    mutate(
      {
        url: '/admin/events',
        attributes: submittingData
      },
      {
        onSuccess: ({ data }) => {
          ToastSuccess('Category successfully created');
          navigate(Routes.EVENTS);
        },
        onError: () => {}
      }
    );
  };

  return (
    <ContentLayout breadcrumb={true}>
      <Formik
        initialValues={{
          status: '',
          title: {
            uz: '',
            ru: '',
            en: ''
          },
          image: {
            mini: '',
            mobileLarge: '',
            webLarge: ''
          },

          description: {
            uz: '',
            ru: '',
            en: ''
          },
          details: [
            {
              uz: '',
              ru: '',
              en: ''
            }
          ],

          durationMinutes: 0,
          screenLanguage: '',
          viewerAge: '',
          trailer: ''
        }}
        onSubmit={addCategory}>
        {({ setFieldValue, values }) => (
          <Form>
            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Названия элемента')}</p>
            <div className="flex gap-6 flex-wrap my-5">
              <FormInput name="nameUz" label={t('name_uz')} />
              <FormInput name="nameRu" label={t('name_ru')} />
              <FormInput name="nameEn" label={t('name_en')} />
            </div>
            <SelectInput
              className={'my-5'}
              label={t('Status')}
              onChange={(ev) => setFieldValue('status', ev?.value)}
              options={STATUS_OPTION_STATUS(t)}
            />
            <p className="text-[20px] font-semibold mt-12 text-gray-2">{t('Картинка')}</p>

            <div className="flex gap-6 flex-wrap mt-6 min-h-[250px]">
              <FileInput
                onChange={(res) => setFieldValue('mini', get(res, 'data.url'))}
                id="icon"
                label={t('Мини (520x520)')}
              />
              <FileInput
                onChange={(res) => setFieldValue('mobileLarge', get(res, 'data.url'))}
                id="icon2"
                label={t('Крупная – Mobile (800x1060)')}
              />
              <FileInput
                onChange={(res) => setFieldValue('webLarge', get(res, 'data.url'))}
                id="icon3"
                label={t('Крупная – Web (1360x640)')}
              />
            </div>

            <hr className="bg-[#1D1D1D90]" />

            <p className="text-[20px] font-semibold my-12 text-gray-3 mb-[32px]">
              {t('Детали сеанса')}
            </p>

            <div className="flex gap-6 flex-wrap mt-6">
              <FormInput type="number" name="durationMinutes" label={t('Длительность (мин)')} />

              <SelectInput
                label={t('Язык показа')}
                onChange={(ev) => setFieldValue('screenLanguage', ev?.value)}
                options={STATUS_OPTION_LANGUAGE(t)}
              />
              <SelectInput
                label={t('Проходной возраст')}
                onChange={(ev) => setFieldValue('viewerAge', ev?.value)}
                options={STATUS_OPTION_AGE(t)}
              />
            </div>

            <p className="text-[20px] font-semibold my-12 text-gray-3 mb-[32px]">
              {t('Дополнительные детали')}
            </p>

            <FormInput wFull name="descUz" label={t('name_uz')} />
            <FormInput wFull name="descRu" label={t('name_ru')} />
            <FormInput wFull name="descEn" label={t('name_en')} />

            <p className="text-[20px] font-semibold my-12 text-gray-3 mb-[32px]">
              {t('Сюжет (описание)')}
            </p>
            <FormInput wFull name="detailsUz" label={t('name_uz')} />
            <FormInput wFull name="detailsRu" label={t('name_ru')} />
            <FormInput wFull name="detailsEn" label={t('name_en')} />

            <p className="text-[20px] font-semibold my-12 text-gray-3 mb-[32px]">
              {t('Параметры')}
            </p>
            <SButton.Group maw={'380px'} bg={'#111111'}>
              <SButton
                onClick={() => setActiveTab(1)}
                fullWidth
                styles={{
                  root: {
                    border: 'none',
                    borderRadius: isEqual(activeTab, 1) ? '12px' : '0'
                  },
                  label: {
                    color: isEqual(activeTab, 1) ? '#C61F1F' : '#fff'
                  }
                }}
                bg={isEqual(activeTab, 1) ? '#1D1D1D' : 'transparent'}
                variant="default">
                {t('Шаблоны')}
              </SButton>
              <SButton
                onClick={() => setActiveTab(2)}
                fullWidth
                styles={{
                  root: {
                    border: 'none',
                    borderRadius: isEqual(activeTab, 2) ? '12px' : '0'
                  },
                  label: {
                    color: isEqual(activeTab, 2) ? '#C61F1F' : '#fff'
                  }
                }}
                bg={isEqual(activeTab, 2) ? '#1D1D1D' : 'transparent'}
                variant="default">
                {t('Создать новый')}
              </SButton>
            </SButton.Group>
            <Flex wrap={'wrap'} className="items-center gap-6 mt-10">
              <Box className="rounded-[12px]" bg={'#111111'} w={'380px'} h={'104px'} p={'20px'}>
                <Flex gap={'16px'} align={'center'}>
                  <Box
                    display={'flex'}
                    bg={'#1D1D1D'}
                    w={'64px'}
                    h={'64px'}
                    className="rounded-[50%] justify-center items-center">
                    <MovieIcon color="#fff" />
                  </Box>
                  <Text c={'#fff'}>{t('Kino')}</Text>
                </Flex>
              </Box>

              <Box className="rounded-[12px]" bg={'#111111'} w={'380px'} h={'104px'} p={'20px'}>
                <Flex gap={'16px'} align={'center'}>
                  <Box
                    display={'flex'}
                    bg={'#1D1D1D'}
                    w={'64px'}
                    h={'64px'}
                    className="rounded-[50%] justify-center items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.99807 7L8.30747 3H11.9981L9.68867 7H5.99807ZM11.9981 7L14.3075 3H17.9981L15.6887 7H11.9981ZM17.9981 7L20.3075 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H5.99807L4 6.46076V19H20V7H17.9981Z"
                        fill="white"
                      />
                    </svg>
                  </Box>
                  <Text c={'#fff'}>{t('Театр')}</Text>
                </Flex>
              </Box>
              <Box className="rounded-[12px]" bg={'#111111'} w={'380px'} h={'104px'} p={'20px'}>
                <Flex gap={'16px'} align={'center'}>
                  <Box
                    display={'flex'}
                    bg={'#1D1D1D'}
                    w={'64px'}
                    h={'64px'}
                    className="rounded-[50%] justify-center items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 20H20V22H12C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 14.2512 21.2561 16.3287 20.0007 18H17.2916C18.9525 16.5341 20 14.3894 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8C14 9.10457 13.1046 10 12 10ZM8 14C6.89543 14 6 13.1046 6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12C10 13.1046 9.10457 14 8 14ZM16 14C14.8954 14 14 13.1046 14 12C14 10.8954 14.8954 10 16 10C17.1046 10 18 10.8954 18 12C18 13.1046 17.1046 14 16 14ZM12 18C10.8954 18 10 17.1046 10 16C10 14.8954 10.8954 14 12 14C13.1046 14 14 14.8954 14 16C14 17.1046 13.1046 18 12 18Z"
                        fill="white"
                      />
                    </svg>
                  </Box>
                  <Text c={'#fff'}>{t('Концерты')}</Text>
                </Flex>
              </Box>
              <Box className="rounded-[12px]" bg={'#111111'} w={'380px'} h={'104px'} p={'20px'}>
                <Flex gap={'16px'} align={'center'}>
                  <Box
                    display={'flex'}
                    bg={'#1D1D1D'}
                    w={'64px'}
                    h={'64px'}
                    className="rounded-[50%] justify-center items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12.366 13.366L8.89708 19.376C9.85124 19.7779 10.8997 20 12 20C12.4622 20 12.9152 19.9608 13.3559 19.8856C13.0984 18.0632 13.3411 16.1601 14.1404 14.3917L12.366 13.366ZM15.8835 15.3974C15.3519 16.6567 15.1671 17.9943 15.2969 19.2905C16.5173 18.7384 17.5737 17.8888 18.3745 16.8346L15.8835 15.3974ZM8.85855 11.3422C7.7265 12.919 6.1993 14.0809 4.49366 14.7699C5.0255 16.2135 5.96211 17.4604 7.16543 18.3745L10.634 12.366L8.85855 11.3422ZM19.9616 11.2111L19.7038 11.3328C18.6197 11.8687 17.6478 12.6548 16.8834 13.6653L19.3756 15.1039C19.7777 14.1495 20 13.1007 20 12C20 11.7338 19.987 11.4706 19.9616 11.2111ZM4 12C4 12.2662 4.013 12.5294 4.03841 12.7889C5.22626 12.2534 6.29168 11.4249 7.11596 10.3355L4.62402 8.89708C4.22214 9.85124 4 10.8997 4 12ZM16.8346 5.62552L13.366 11.634L15.1407 12.6588C16.2731 11.0812 17.8009 9.91878 19.5073 9.22969C19.0134 7.89068 18.1702 6.71922 17.0884 5.82653L16.8346 5.62552ZM12 4C11.5375 4 11.0841 4.03925 10.6431 4.1146C10.9016 5.93748 10.6588 7.8408 9.85915 9.60931L11.634 10.634L15.1029 4.62402C14.1488 4.22214 13.1003 4 12 4ZM8.70313 4.70955L8.51189 4.79845C7.37266 5.35126 6.38436 6.16644 5.62552 7.16543L8.11608 8.60349C8.64798 7.34403 8.83295 6.00607 8.70313 4.70955Z"
                        fill="white"
                      />
                    </svg>
                  </Box>
                  <Text c={'#fff'}>{t('Спорт')}</Text>
                </Flex>
              </Box>
              <Box className="rounded-[12px]" bg={'#111111'} w={'380px'} h={'104px'} p={'20px'}>
                <Flex gap={'16px'} align={'center'}>
                  <Box
                    display={'flex'}
                    bg={'#1D1D1D'}
                    w={'64px'}
                    h={'64px'}
                    className="rounded-[50%] justify-center items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.23509 6.45329C4.85101 7.89148 4 9.84636 4 12C4 16.4183 7.58172 20 12 20C13.0808 20 14.1116 19.7857 15.0521 19.3972C15.1671 18.6467 14.9148 17.9266 14.8116 17.6746C14.582 17.115 13.8241 16.1582 12.5589 14.8308C12.2212 14.4758 12.2429 14.2035 12.3636 13.3943L12.3775 13.3029C12.4595 12.7486 12.5971 12.4209 14.4622 12.1248C15.4097 11.9746 15.6589 12.3533 16.0043 12.8777C16.0425 12.9358 16.0807 12.9928 16.1198 13.0499C16.4479 13.5297 16.691 13.6394 17.0582 13.8064C17.2227 13.881 17.428 13.9751 17.7031 14.1314C18.3551 14.504 18.3551 14.9247 18.3551 15.8472V15.9518C18.3551 16.3434 18.3168 16.6872 18.2566 16.9859C19.3478 15.6185 20 13.8854 20 12C20 8.70089 18.003 5.8682 15.1519 4.64482C14.5987 5.01813 13.8398 5.54726 13.575 5.91C13.4396 6.09538 13.2482 7.04166 12.6257 7.11976C12.4626 7.14023 12.2438 7.12589 12.012 7.11097C11.3905 7.07058 10.5402 7.01606 10.268 7.75495C10.0952 8.2232 10.0648 9.49445 10.6239 10.1543C10.7134 10.2597 10.7307 10.4547 10.6699 10.6735C10.59 10.9608 10.4286 11.1356 10.3783 11.1717C10.2819 11.1163 10.0896 10.8931 9.95938 10.7412C9.64554 10.3765 9.25405 9.92233 8.74797 9.78176C8.56395 9.73083 8.36166 9.68867 8.16548 9.64736C7.6164 9.53227 6.99443 9.40134 6.84992 9.09302C6.74442 8.8672 6.74488 8.55621 6.74529 8.22764C6.74529 7.8112 6.74529 7.34029 6.54129 6.88256C6.46246 6.70541 6.35689 6.56446 6.23509 6.45329ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
                        fill="white"
                      />
                    </svg>
                  </Box>

                  <Text c={'#fff'}>{t('Конференции')}</Text>
                </Flex>
              </Box>
            </Flex>

            <hr className="bg-[#1D1D1D90]" />

            <FieldArray
              name="subDetails"
              render={(arrayHelpers) => (
                <div>
                  {values?.subDetails?.map((subDetail, index) => (
                    <Accordion key={index} defaultValue={`subDetail-${index}`}>
                      <p className="text-[20px] font-semibold my-12 text-gray-3 mb-[32px]">
                        {t('Заголовок')} {index + 1}
                      </p>
                      <Accordion.Item label={`Subdetail ${index + 1}`}>
                        <div>
                          <div className="flex gap-6 flex-wrap my-5">
                            <FormInput
                              name={`subDetails.${index}.title.uz`}
                              label={t('Title (Uz)')}
                            />
                            <FormInput
                              name={`subDetails.${index}.title.ru`}
                              label={t('Title (Ru)')}
                            />
                            <FormInput
                              name={`subDetails.${index}.title.en`}
                              label={t('Title (En)')}
                            />
                          </div>

                          <div>
                            <SButton.Group my={'32px'} bg={'#111111'}>
                              <SButton
                                maw={'190px'}
                                onClick={() => setActiveTab2(1)}
                                fullWidth
                                styles={{
                                  root: {
                                    border: 'none',
                                    borderRadius: isEqual(activeTab2, 1) ? '12px' : '0'
                                  },
                                  label: {
                                    color: isEqual(activeTab2, 1) ? '#C61F1F' : '#fff'
                                  }
                                }}
                                bg={isEqual(activeTab2, 1) ? '#1D1D1D' : 'transparent'}
                                variant="default">
                                {t('Шаблоны')}
                              </SButton>
                              <SButton
                                maw={'190px'}
                                onClick={() => setActiveTab2(2)}
                                fullWidth
                                styles={{
                                  root: {
                                    border: 'none',
                                    borderRadius: isEqual(activeTab2, 2) ? '12px' : '0'
                                  },
                                  label: {
                                    color: isEqual(activeTab2, 2) ? '#C61F1F' : '#fff'
                                  }
                                }}
                                bg={isEqual(activeTab2, 2) ? '#1D1D1D' : 'transparent'}
                                variant="default">
                                {t('Шаблоны')}
                              </SButton>
                              <SButton
                                maw={'190px'}
                                onClick={() => setActiveTab2(3)}
                                fullWidth
                                styles={{
                                  root: {
                                    border: 'none',
                                    borderRadius: isEqual(activeTab2, 3) ? '12px' : '0'
                                  },
                                  label: {
                                    color: isEqual(activeTab2, 3) ? '#C61F1F' : '#fff'
                                  }
                                }}
                                bg={isEqual(activeTab2, 3) ? '#1D1D1D' : 'transparent'}
                                variant="default">
                                {t('Шаблоны')}
                              </SButton>
                              <SButton
                                maw={'190px'}
                                onClick={() => setActiveTab2(4)}
                                fullWidth
                                styles={{
                                  root: {
                                    border: 'none',
                                    borderRadius: isEqual(activeTab2, 4) ? '12px' : '0'
                                  },
                                  label: {
                                    color: isEqual(activeTab2, 4) ? '#C61F1F' : '#fff'
                                  }
                                }}
                                bg={isEqual(activeTab2, 4) ? '#1D1D1D' : 'transparent'}
                                variant="default">
                                {t('Шаблоны')}
                              </SButton>
                              <SButton
                                maw={'190px'}
                                onClick={() => setActiveTab2(5)}
                                fullWidth
                                styles={{
                                  root: {
                                    border: 'none',
                                    borderRadius: isEqual(activeTab2, 5) ? '12px' : '0'
                                  },
                                  label: {
                                    color: isEqual(activeTab2, 5) ? '#C61F1F' : '#fff'
                                  }
                                }}
                                bg={isEqual(activeTab2, 5) ? '#1D1D1D' : 'transparent'}
                                variant="default">
                                {t('Создать новый')}
                              </SButton>
                            </SButton.Group>
                          </div>

                          <p className="text-[20px] font-semibold my-12 text-gray-3 mb-[32px]">
                            {t('Значение')} {index + 1}
                          </p>
                          <div className="flex gap-6 flex-wrap my-5">
                            <FormInput
                              name={`subDetails.${index}.meta[0].left.uz`}
                              label={t('Meta Left (Uz)')}
                            />
                            <FormInput
                              name={`subDetails.${index}.meta[0].right.uz`}
                              label={t('Meta Right (Uz)')}
                            />
                          </div>

                          <div className="flex gap-6 flex-wrap my-5">
                            <FormInput
                              name={`subDetails.${index}.meta[0].left.ru`}
                              label={t('Meta Left (Ru)')}
                            />

                            <FormInput
                              name={`subDetails.${index}.meta[0].right.ru`}
                              label={t('Meta Right (Ru)')}
                            />
                          </div>
                          <div className="flex gap-6 flex-wrap my-5">
                            <FormInput
                              name={`subDetails.${index}.meta[0].left.en`}
                              label={t('Meta Left (En)')}
                            />

                            <FormInput
                              name={`subDetails.${index}.meta[0].right.en`}
                              label={t('Meta Right (En)')}
                            />
                          </div>
                        </div>
                      
                      </Accordion.Item>
                    </Accordion>
                  ))}

                  <Button
                    type="button"
                    // onClick={() =>
                    //   arrayHelpers.push({
                    //     title: {
                    //       uz: '',
                    //       ru: '',
                    //       en: ''
                    //     },
                    //     meta: [
                    //       {
                    //         left: {
                    //           uz: '',
                    //           en: '',
                    //           ru: ''
                    //         },
                    //         right: {
                    //           uz: '',
                    //           en: '',
                    //           ru: ''
                    //         }
                    //       }
                    //     ]
                    //   })
                    // }
                    className="bg-accent-green my-5">
                    {t('Добавить заголовок')}
                  </Button>
                </div>
              )}
            />

            <hr className="bg-[#1D1D1D90]" />
            <p className="text-[20px] font-semibold my-12 text-gray-3 mb-[32px]">{t('Ссылка на трейлер')}</p>
            <FormInput wFull name="trailer" label={t('Гиперссылка')} />
            <div className="flex items-center gap-6 mt-10">
              <Button className="bg-main-black">{t('Отменить')}</Button>
              <Button type="submit" className="bg-accent-green">
                {t('Добавить')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ContentLayout>
  );
}

export default CreateEvent;

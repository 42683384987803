import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  languages: {
    en: {
      home: 'HOME1',
      name_uz: 'UZBEK'
    },
    uz: {
      home: 'UY2',
      name_uz: "O'zbek"
    },
    ru: {
      home: 'Dom2',
      name_uz: 'Uzbek'
    }
  },
  hall: {}
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getAllLanguages: (state, action) => {
      state.languages = { ...action.payload };
    },
    setHall: (state, action) => {
      state.hall = {
        ...state.hall,
        ...action.payload
      };
    },
    clearHall: (state) => {
      // Clear the entire hall state
      state.hall = {};
    }
  }
});

export const { getAllLanguages, setHall,clearHall } = settingsSlice.actions;

export default settingsSlice;
